.upcoming-classes-list {
  margin-top: 0.35vw;
}

.upcoming-classes-list-item {
  display: grid;
  grid-template-columns: 0.8fr 1fr 3.2fr;
  align-items: center;
  color: #424242;
  font-size: 1.01vw;
  /* font-size: 19px; */
  padding: 1.25vw 0.5vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.upcoming-classes-list-item:last-child {
  border-bottom: none;
}

.upcoming-classes-list-item .date {
  font-weight: 700;
}

.upcoming-classes-list-item .time {
  color: #0c66e4;
  font-weight: 650;
}

.upcoming-classes-list-item .title {
  font-weight: 500;
}

@media screen and (max-width: 750px) {
  .upcoming-classes-list {
    margin-top: 5vw;
  }
  .upcoming-classes-list-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.8vw;
    border-bottom: unset;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding: 3vw 2.5vw;
  }

  .upcoming-classes-list-item .time-and-date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upcoming-classes-list-item .time {
    color: #212121;
    font-size: 4.5vw;
    font-weight: 700;
  }

  .upcoming-classes-list-item .date {
    color: #0c66e4;
    font-weight: 500;
    font-size: 4vw;
  }

  .upcoming-classes-list-item .title {
    color: #424242;
    font-weight: 400;
    font-size: 3.8vw;
  }
}
