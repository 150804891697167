.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2vw;
  text-align: center;
}

.sectionTitle {
  /* font-size: 2.8vw; */
  font-size: 46px;
  font-weight: 700;
  color: #424242;
  padding-top: 3vw;
  /* padding-right: 14vw;
  padding-left: 14vw; */
  width: 845px;
  border-top: 1px solid #bdbdbd;
}

.description {
  /* font-size: 1.2vw; */
  font-size: 22px;
  color: #757575;
  /* margin-bottom: 4.5vw; */
  margin-bottom: 69px;
  text-align: center;
}

.cardsGrid {
  display: flex;
  margin-bottom: 5vh;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 4vw; */
  padding-bottom: 61px;
  border-bottom: 1px solid #bdbdbd;
}

.sectionCard {
  display: flex;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 15vw; */
  /* width: 15.5vw; */
  width: 240px;
  /* max-width: fit-content; */
  justify-content: flex-start;
  /* margin-right: 1.5vw; */
  /* margin-left: 1.5vw; */
  margin: 0 22px;
}

.title {
  color: #fff;
  /* font-size: 1.3vw; */
  font-size: 22px;
  font-weight: 600;
  padding-top: 24px;
  /* margin-bottom: 1.5vw; */
  margin-bottom: 24px;
}

.line {
  content: "";
  height: 170px;
  width: 1px;
  align-items: center;
  align-self: center;
  border-right: 1px solid #bdbdbd;
}

.price,
.duration {
  color: #fff;
  font-weight: 600;
  /* font-size: 1.6vw; */
  font-size: 26px;
}

.card {
  background-color: #0c66e4;
  border-radius: 20px;
}

.button {
  display: flex;
  /* margin-top: 2vw; */
  margin-top: 28px;
}

.bgWhiteBtn {
  border: 3px solid #0c66e4;
  background-color: #fff;
  color: #0c66e4;
  border-radius: 50px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 17px;
  /* padding: 0.6vw 2.5vw; */
  padding: 9px 38px;
  margin-bottom: 24px;
  font-family: inherit;
}

.bgWhiteBtn:hover {
  background-color: #0c66e488;
  border: 3px solid #fff;
  color: white;
}

.sectionCard:last-child .line {
  display: none;
}

@media screen and (max-width: 750px) {
  .section {
    margin-top: 0;
  }

  .sectionTitle {
    /* font-size: 3vh; */
    font-size: 31px;
    padding-top: 40px;
    margin-top: 50px;
    padding-bottom: 1.5vh;
    text-align: center;
    width: 90%;
    /* margin-right: 10px; */
  }

  .description {
    /* font-size: 2vh; */
    font-size: 19.5px;
    text-align: center;
    margin-bottom: 3.5vh;
    padding: 0 5vw;
  }

  .cardsGrid {
    flex-direction: column;
    /* width: 100%; */
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5vh;
  }

  .line {
    display: none;
  }

  .card {
    width: 80vw;
    height: fit-content;
    margin-bottom: 2vh;
  }

  .title {
    /* font-size: 2.5vh; */
    font-size: 25px;
    font-weight: 600;
    padding-top: 3vh;
    margin-bottom: 2vh;
  }

  .price {
    /* font-size: 2.7vh; */
    font-size: 29px;
  }

  .duration {
    /* font-size: 2.7vh; */
    font-size: 29px;
  }

  .button {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .bgWhiteBtn {
    /* font-size: 2.5vh; */
    font-size: 24px;
    /* padding: 1.5vh 5vh; */
    padding: 15px 50px;
  }
}
