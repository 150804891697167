.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3vw 10%;
  margin-bottom: 3vw;
  width: 100%;
}

.testimonials .titleHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 4vh;
  position: relative;
}

.testimonials h2 {
  /* font-size: 1.8vw; */
  font-size: var(--section-title-font-size);
  font-weight: bold;
}

.testimonials ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5vw;
  width: 80vw;
}

.testimonialCard {
  border-radius: 20px;
  border: 0;
  background-color: #f5f5f5;
  padding: 25px 2.5vw 0 2.5vw;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 275px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 38.75vw;
}

.testimonialCard .title {
  display: flex;
  justify-content: flex-start;
}

.textBold {
  transform: translateY(75%);
  margin-left: 75px;
  margin-bottom: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.testimonialCard .title .quotation {
  font-size: 60px;
  position: absolute;
  color: #496ce6;
  color: var(--primary-color);
}

.testimonialCard .title span {
  /* font-size: 1.3vw; */
  font-size: var(--card-title-font-size);
  font-weight: bold;
  text-align: start;
  color: #212121;
}

.testimonialCard .desc {
  /* font-size: 1vw; */
  font-size: var(--secondary-font-size);
  text-align: start;
  color: #757575;
  line-height: 1.4;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.testimonialCard .studentInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  /* bottom: 4vh; */
  bottom: 30px;
}

.studentInfo img {
  width: 40px;
  height: 40px;
  margin-right: 1vw;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.details .name {
  /* font-size: 1.2vw; */
  font-size: 16.86px;
  font-weight: bold;
  text-align: start;
  color: #212121;
  margin-bottom: 5px;
}

/* MIDNIGHTZEN */

.testimonials.midnightzen {
  background-color: #000;
  color: #fff;
  font-family: "Nunito Sans", serif;
  padding: 25px 10% 50px;
  border-bottom: 1px solid #757575;
  margin-bottom: unset;

  .testimonialCard {
    background-color: #212121;
    height: 350px;
  }

  .testimonialCard .desc {
    color: #bbb;
  }

  .details .name {
    color: #fff;
  }

  .studentInfo {
    border-top: 1px solid #bdbdbd;
    width: 85%;
    padding-top: 25px;
  }

  .textBold {
    color: #fff;
  }

  /* .quotation {
    color: #496ce6;
  } */
}

/* ELEVATE */

.testimonials.elevate {
  width: 80vw;
  margin: 0 auto;
  font-family: "Nunito", sans-serif;
  padding: 0;
  border-top: 1px solid #bdbdbd;
  padding-bottom: 2.5vw;
  border-bottom: 1px solid #bdbdbd;
}

.testimonials.elevate .titleHeader {
  justify-content: flex-start;
  margin: 3vw 0;
}

.testimonials.elevate .testimonialCard,
.testimonials.edgefusion .testimonialCard {
  position: relative;
  overflow: visible;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonials.edgefusion .testimonialCard {
  height: 300px;
  padding-top: 40px;
}

.testimonials.elevate .studentInfo,
.testimonials.edgefusion .studentInfo {
  width: unset;
  order: 0;
  position: unset;
  flex-direction: column;
  justify-content: center;
}

.testimonials.elevate .studentInfo img,
.testimonials.edgefusion .studentInfo img {
  width: 75px;
  height: 75px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin-bottom: 14px;
  margin-right: unset;
}

.testimonials.edgefusion .testimonialCard img {
  width: 58px;
  height: 58px;
}

.testimonials.elevate .textBold {
  /* color: #496ce6; */
  color: var(--primary-color);
  margin-left: 0;
  transform: translateY(40%);
  margin-bottom: 22px;
}

.testimonials.elevate .title,
.testimonials.edgefusion .title {
  order: 1;
  font-family: "Nunito Sans", serif;
  height: unset;
}

.testimonials.elevate .desc,
.testimonials.edgefusion .desc {
  order: 2;
  color: #5d5d5d;
  text-align: center;
  font-family: "Nunito Sans", serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.testimonials.elevate .details span {
  text-align: center;
  margin: 0 auto;
}

.testimonials.elevate .testimonialCard:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.testimonials.elevate .quotation,
.testimonials.edgefusion .quotation {
  position: absolute;
  left: 15px;
  /* top: 2.5vw; */
  top: 0;
  transform: translateY(-35%);
  overflow: visible;
  /* color: #496ce6; */
}

.testimonials.edgefusion {
  border-bottom: 1px solid #bdbdbd;
  font-family: "Inter", sans-serif;
  width: 80vw;
  border-top: 1px solid #bdbdbd;
  margin: 0 auto;
}

.testimonials.edgefusion .titleHeader {
  /* font-size: 1.25vw; */
  font-style: italic;
  margin: 1vh 0 6vh;
}

.testimonials.edgefusion .testimonialCard {
  border-radius: unset;
  background-color: #fff;
}

.testimonials.edgefusion .title {
  font-style: italic;
}

.testimonials.edgefusion .testimonialCard .title span {
  font-weight: 800;
  text-align: center;
}

.testimonials.edgefusion .desc {
  line-height: 1.6;
  position: relative;
  top: -10px;
}

.testimonials.edgefusion .textBold {
  margin-left: unset;
  margin-bottom: 30px;
  position: relative;
  top: -10px;
  margin-top: 8px;
}

.testimonials.edgefusion ul {
  gap: 3vw;
}

.testimonials.edgefusion .details .name {
  font-weight: 600;
  color: #424242;
  /* font-size: 1vw; */
}

/* MINIMALIST */

.testimonials.minimalist .titleHeader {
  justify-content: flex-start;
  margin: 0;
}

.testimonials.minimalist {
  font-family: "Manrope", serif;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 5vh;
  margin-bottom: 8vh;
}

.testimonials.minimalist .testimonialCard {
  background-color: #fff;
  border-radius: 0;
  /* height: 42vh; */
  height: 270px;
  /* border-bottom: 0.5px solid #946199; */
}

.testimonials.minimalist ul {
  gap: 0;
}

.testimonials.minimalist .quotation {
  color: #946199;
  color: var(--primary-color);
}

.testimonials.minimalist ul > li {
  border-bottom: 0.5px solid #946199;
  border-color: var(--primary-color);
}

.testimonials.minimalist ul li.noBorder {
  border-bottom: unset;
}

.testimonials.minimalist .textBold {
  transform: translateY(95%);
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .testimonialCard {
    height: 290px;
  }
}

@media screen and (max-width: 1414px) {
  .testimonials.edgefusion .testimonialCard {
    height: 330px;
  }

  .testimonials.minimalist .testimonialCard {
    height: 310px;
  }
}

@media screen and (max-width: 750px) {
  .testimonials {
    /* padding: 1vh 4vw 5vh 4vw; */
    margin: 0;
    padding: unset;
    margin: 2vh 0 5vh 0;
    width: 100%;
  }

  .testimonials .titleHeader {
    margin-bottom: 3vh;
  }

  .testimonials .titleHeader h2 {
    margin-block-end: 0;
    /* font-size: 3vh; */
  }

  .testimonials ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
  }

  .testimonialCard {
    width: 90vw;
    height: unset;
    box-shadow: unset;
    /* padding: 2vh 3vh 2vh 3vh; */
    padding: 15px 25px;
    max-width: unset;
  }

  .testimonialCard .title {
    margin-bottom: 30px;
  }

  .textBold {
    /* transform: translateY(25%); */
    position: relative;
    top: -8px;
    margin-bottom: 0;
    margin-left: 62px;
    font-size: 16px;
  }

  .testimonialCard .title .quotation {
    font-size: 50px;

    top: 12px;
  }

  .testimonialCard .title span {
    font-size: 16px;
  }

  .testimonialCard .desc {
    /* font-size: 2vh; */
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    margin-bottom: 65px;
    -webkit-box-orient: vertical;
  }

  .studentInfo img {
    width: 45px;
    height: 45px;
    margin-right: 1.5vh;
  }

  .details .name {
    font-size: 13.82px;
  }

  .testimonialCard .studentInfo {
    bottom: 10px;
    margin-bottom: 1vh;
  }

  /* MIDNIGHTZEN */
  .testimonials.midnightzen {
    border-bottom: 2px solid #757575;
    margin: unset;
  }

  .testimonials.midnightzen .testimonialCard {
    height: unset;
  }

  .testimonials.midnightzen .studentInfo {
    border: unset;
  }

  /* ELEVATE */
  .testimonials.elevate,
  .testimonials.edgefusion {
    border-bottom: unset;
    border-top: unset;

    .quotation {
      top: 10px;
      transform: unset;
      /* font-size: 50px; */
    }

    .testimonialCard {
      padding-bottom: 10px;
      border-radius: 15px;
      padding-top: 10px;
    }

    .titleHeader {
      justify-content: center;
      margin: 2vh 0;
    }

    .title {
      margin-bottom: 0;
    }

    .studentInfo {
      margin-bottom: 15px;
      gap: 5px;
      margin-top: 5px;
    }

    .textBold {
      transform: translateY(-10%);
    }

    .studentInfo img {
      width: 50px;
      height: 50px;
    }

    .desc {
      width: 85%;
      margin-bottom: 0;
      position: relative;
      top: -5px;
      margin-bottom: 8px;
    }
  }

  /* EDGEFUSION */
  .testimonials.edgefusion .details .name {
    font-weight: 700;
    /* font-size: 18px; */
  }

  .testimonials.edgefusion .desc {
    line-height: unset;
    width: 90%;
  }

  .testimonials.edgefusion .title .textBold {
    color: #496ce6;
    font-style: normal;
  }

  /* MINIMALIST */

  .testimonials.minimalist {
    font-family: "Poppins", serif;
  }

  .testimonials.minimalist .titleHeader {
    justify-content: center;
  }

  .testimonials.minimalist ul > li {
    border-bottom: none;
  }

  .testimonials.minimalist .title {
    position: relative;
    margin-bottom: 0;
  }

  .testimonials.minimalist .textBold {
    transform: translateY(180%);
  }

  .testimonials.minimalist .desc {
    margin-bottom: 85px;
  }

  .testimonials.minimalist .testimonialCard {
    padding: 0 20px;
    height: unset;
  }
}
