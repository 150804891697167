.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  /* font-size: 2.8vw; */
  font-size: 46px;
  font-weight: 700;
  color: #424242;
}

.desc {
  /* font-size: 1.2vw; */
  font-size: 22px;
  color: #757575;
  /* margin-bottom: 2.5vw; */
  margin-bottom: 38px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 70%;
  column-gap: 5vw;
  row-gap: 57px;
}

.imgRounded .image {
  width: 85px;
  height: 85px;
  /* margin-bottom: 1vw; */
  margin-bottom: 15.3px;
}

.whyTitle {
  /* font-size: 1.5vw; */
  font-size: 22px;
  font-weight: 650;
  /* margin-bottom: 0.8vw; */
  margin-bottom: 12px;
  text-align: center;
}

.whyDesc {
  text-align: center;
  color: #757575;
  /* font-size: 1vw; */
  font-size: 17px;
}

@media screen and (max-width: 750px) {
  .cardGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .title {
    /* font-size: 3vh; */
    font-size: 30px;
    width: 90%;
    padding-top: 20px;
    text-align: center;
    border-top: 1px solid #bdbdbd;
  }

  .desc {
    /* font-size: 1.9vh; */
    font-size: 19px;
    margin-top: 1.5vh;
    margin-bottom: 4vh;
    text-align: center;
    padding: 0 5vw;
  }

  .imgRounded .image {
    /* width: 7vh;
    height: 7vh; */
    width: 80px;
    height: 80px;
  }

  .whyTitle {
    /* font-size: 2vh; */
    font-size: 22px;
    margin-bottom: 1vh;
  }

  .whyDesc {
    /* font-size: 1.9vh; */
    font-size: 19.5px;
    max-width: 85%;
    margin-bottom: 3vh;
  }
}
