#show-all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: inherit;
}

#show-all:hover {
  opacity: 0.5;
}

#show-all span {
  margin-right: 0.6vw;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
}

#read-more {
  display: flex;
  font-size: inherit;
  cursor: pointer;
  align-items: center;
}

#read-more span {
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  margin-right: 5px;
}

.read-more-icon {
  display: inherit;
}

.know-more-icon {
  display: inherit;
  font-size: 20px;
}

#read-more:hover {
  opacity: 0.5;
}

.bgWhiteBtn #button {
  border: 3px solid #0c66e488;
  background-color: #fff;
  color: #0c66e4;
  border-radius: 50px;
  font-weight: 700;
  text-align: center;
  /* padding: 10px 30px; */
  font-size: 1vw;
  padding: 0.6vw 2vw;
  font-family: inherit;
}

.bgBlueBtn #button {
  border: none;
  background-color: #0c66e4;
  color: #fff;
  padding: 0.75vw 2vw;
  /* padding: 10px 30px; */
  font-size: 1vw;
  border-radius: 50px;
  font-family: inherit;
}

@media screen and (max-width: 750px) {
  #show-all span {
    margin-right: 0;
    text-transform: capitalize;
    font-weight: normal;
  }
}
