.gallery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  margin-top: 2vh;
}

.gallery.simpleui {
  font-family: "Poppins", serif;
  font-family: inherit;
}

.titleHeader {
  text-align: center;
  padding: 4vh 0;
}

.titleHeader h2 {
  font-size: var(--section-title-font-size);
  font-weight: 700;
}

.carouselWrapper {
  display: flex;
  justify-content: stretch;
  width: 70vw;
  height: 35vw;
  gap: 2.5vw;
  position: relative;
}

.prevImage img,
.nextImage img {
  height: 30vw;
  object-fit: cover;
  width: 100%;
}

.carouselCard {
  opacity: 0;
  pointer-events: none;
  width: 48%;
  max-width: 48%;
  height: 30vw;
  position: absolute;
  /* left: 50%;
  transition: 1s; */
}

.prevImage,
.nextImage {
  width: 25%;
}

.nextImage {
  position: absolute;
  left: 75%;
}

.nextImage img {
  height: 100%;
  height: 30vw;
}

.carouselCardActive img {
  width: 100%;
  height: 30vw;
  object-fit: cover;
}

.carouselCardActive {
  opacity: 1;
  position: absolute;
  pointer-events: visible;
  animation: slideIn 0.5s ease-out;
  left: 26%;
}

.gallery .paginationDotActive {
  background-color: #496ce6;
  background-color: var(--primary-color);
}

/* ELEVATE */
.gallery.elevate .arrow {
  color: #fff;
}

/* .gallery.elevate .paginationDotActive {
  background-color: #496ce6;
  background-color: var(--primary-color);
} */

/* SIMPLEUI */

/* .gallery.simpleui .paginationDotActive {
  background-color: #496ce6;
  background-color: var(--primary-color);
} */

.gallery.simpleui .arrow {
  color: #fff;
}

/* EDGEFUSION */

/* .gallery.edgefusion .paginationDotActive {
  background-color: #496ce6;
  background-color: var(--primary-color);
} */

.gallery.edgefusion .arrow {
  color: #fff;
}

@keyframes prevSlideIn {
  0% {
    left: 50%;
    opacity: 0;
    width: 100%;
  }
  100% {
    left: 0%;
    /* transform: translateX(-100%); */
    opacity: 1;
    width: 50%;
  }
}

@keyframes slideIn {
  0% {
    left: 50%;
    opacity: 0;
  }
  100% {
    left: 26%;
    opacity: 1;
  }
}

.carouselPagination {
  position: absolute;
  top: 92%;
  left: 50%;
  padding-top: 10px;
  transform: translateX(-50%);
}

.paginationDot {
  height: 0.8vw;
  width: 0.8vw;
  background-color: #bfb4b4;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 3vw;
}

.paginationDot:hover {
  transform: scale(1.2);
}

.arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftArrow,
.rightArrow {
  width: 60px;
  height: 60px;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transform: translateY(-25%);
}

.leftArrow {
  margin-left: 2vw;
  font-size: 30px;
  position: absolute;
  left: -6%;
}

.rightArrow {
  margin-right: 2vw;
  font-size: 30px;
  position: absolute;
  right: -6%;
}

/* ELEVATE */

.gallery.elevate {
  width: unset;
  margin: 0 auto;
  position: relative;
  padding-bottom: 2.5vw;
  font-family: "Nunito", sans-serif;

  .titleHeader {
    text-align: start;
    padding: unset;
    padding-left: 10vw;
    width: 80vw;
    align-self: flex-start;
    margin: 3vw 0;
  }

  .titleHeader h2 {
    /* font-size: 1.8vw; */
    font-weight: bold;
    font-family: "Nunito", sans-serif;
  }
  .carouselWrapper {
    width: 80vw;
    max-width: 80vw;
  }

  .prevImage img {
    border-radius: 15px 0 0 15px;
  }

  .nextImage img {
    border-radius: 0 15px 15px 0;
  }

  .leftArrow {
    left: -4vw;
    transform: translateY(-50%);
    z-index: 5;
    overflow-x: visible;
    position: absolute;
    background-color: #496ce6;
    background-color: var(--primary-color);
  }
  .rightArrow {
    right: -4vw;
    transform: translateY(-50%);
    background-color: #496ce6;
    background-color: var(--primary-color);
    z-index: 5;
  }
}

/* EDGEFUSION */

.gallery.edgefusion {
  font-family: "Inter", serif;
  margin-bottom: 4vh;
}

.gallery.edgefusion .titleHeader {
  font-style: italic;
}

.gallery.edgefusion .carouselWrapper {
  width: 80vw;
}

.gallery.edgefusion .leftArrow {
  left: -4.5%;
  transform: translateY(-50%);
  width: 3.5vw;
  height: 3.5vw;
  background-color: #496ce6;
  background-color: var(--primary-color);
}

.gallery.edgefusion .rightArrow {
  right: -5%;
  transform: translateY(-50%);
  width: 3.5vw;
  height: 3.5vw;
  background-color: #496ce6;
  background-color: var(--primary-color);
}

@media screen and (max-width: 750px) {
  .titleHeader {
    font-size: 20px;
    padding: 3vh 0 3vh;
    margin: 0 2vw;
  }

  .prevImage {
    display: none;
  }

  .nextImage {
    display: none;
  }

  .carouselCard {
    width: 100%;
    max-width: 100%;
    height: 70vh;
    left: 100%;
  }

  .carouselCardActive {
    left: 0;
  }

  .leftArrow,
  .rightArrow {
    font-size: 20px;
    width: 50px;
    height: 50px;
    padding: 10px;
  }

  .carouselCard img {
    height: 70vh;
  }

  .carouselCardActive img {
    height: 70vh;
    border-radius: 10px;
  }

  .carouselWrapper {
    height: 75vh;
    width: 90vw;
  }

  .leftArrow {
    left: 0;
  }

  .rightArrow {
    right: 0;
  }

  .carouselPagination {
    top: 95%;
    transform: translateX(-55%);
  }

  .paginationDot {
    width: 7px;
    height: 7px;
    margin-left: 5px;
  }

  /* ELEVATE */

  .gallery.elevate .titleHeader {
    padding-left: unset;
    text-align: center;
    align-self: center;
  }

  .gallery.elevate .titleHeader h2 {
    /* font-size: 3vh; */
    text-align: center;
    margin: 2vh 0;
  }

  .gallery.elevate .carouselWrapper {
    min-width: 90vw;
    max-width: 90vw;
    height: 65vh;
  }

  .gallery.elevate .carouselCard {
    height: 60vh;
  }

  .gallery.elevate .carouselCardActive img {
    height: 60vh;
    border-radius: 15px;
  }

  .gallery.elevate .leftArrow,
  .gallery.elevate .rightArrow {
    background-color: rgb(0, 0, 0, 0.5);
    font-size: 25px;
  }

  .gallery.elevate .leftArrow {
    left: 0;
  }

  .gallery.elevate .rightArrow {
    right: 0;
  }

  @keyframes slideIn {
    0% {
      left: 100%;
      opacity: 0;
    }
    100% {
      left: 0%;
      opacity: 1;
    }
  }

  /* EDGEFUSION */

  .gallery.edgefusion {
    margin-bottom: 4vh;
  }

  .gallery.edgefusion .titleHeader {
    font-size: 20px;
    font-style: normal;
  }

  .gallery.edgefusion .carouselWrapper {
    width: 90vw;
    border-radius: 20px;
  }

  .gallery.edgefusion .carouselWrapper img {
    border-radius: 20px;
  }

  .gallery.edgefusion .rightArrow,
  .gallery.edgefusion .leftArrow {
    width: 50px;
    height: 50px;
    background-color: rgb(0, 0, 0, 0.5);
    font-size: 25px;
  }

  .gallery.edgefusion .rightArrow {
    right: 2%;
    top: 45%;
  }

  .gallery.edgefusion .leftArrow {
    left: 2%;
    top: 45%;
  }
}
