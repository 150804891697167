@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.previewImage {
  position: relative;
  /* font-family: "Inter", sans-serif; */
  padding: 0;
  max-height: 100vh;
  width: 100%;
  height: 62vw;
}

.textOverlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.85vw;
}

.text {
  position: absolute;
  top: 25%;
  left: 8%;
  width: 80%;
}

.textOverlay .title {
  font-size: 47.35px;
  /* line-height: 1; */
  font-weight: bolder;
  /* text-transform: uppercase; */
  color: white;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textOverlay .desc {
  font-size: 32.6px;
  font-weight: 200;
  color: #fff;
  display: block;
  margin-top: 1.6vh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.whatsapp {
  z-index: 2;
  position: fixed;
  bottom: 10vh;
  right: 3.5%;
  cursor: pointer;
}

.whatsapp img {
  width: 5vw;
  height: 5vw;
}

/* SIMPLEUI */

.previewImage.simpleui {
  font-family: "Poppins", serif;
  font-family: inherit;
}

/* MIDNIGHTZEN */

.previewImage.midnightzen {
  display: flex;
  max-height: 100vh;
  align-items: center;
  height: 60vw;
  padding: 5vh 5vw;
  background-color: #000000;
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  border-bottom: 1px solid #757575;
}

.previewImage.midnightzen .image img {
  width: 40vw;
  height: 40vw;
  max-height: 70vh;
  object-fit: cover;
  border-radius: 20px;
}

.previewImage.midnightzen .textOverlay {
  background: none;
  position: unset;
}

.previewImage.midnightzen .text {
  position: unset;
}

.previewImage.midnightzen .textOverlay .title {
  line-height: 1.5;
  font-family: "Nunito", sans-serif;
}

.previewImage.midnightzen .textOverlay .desc {
  margin-top: 33px;
  font-weight: 400;
}

/* ELEVATE */

.previewImage.elevate {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-family: inherit;
}

.previewImage.elevate .text {
  /* top: 35%; */
  left: unset;
  width: unset;
  margin: 0 auto;
  width: 100%;
}

.previewImage.elevate .title {
  font-weight: 800;
  font-family: "Nunito", sans-serif;
  font-family: inherit;
}

.previewImage.elevate .desc {
  font-weight: 300;
}

.previewImage.elevate .title,
.previewImage.elevate .desc {
  max-width: 60%;
  margin: 2vw auto;
}

/* EDGEFUSION */

.previewImage.edgefusion {
  font-family: "Inter", sans-serif;
  font-family: inherit;
  width: 92vw;
  margin: 0 auto;
}

.previewImage.edgefusion .textOverlay {
  position: unset;
}

.previewImage.edgefusion .text {
  /* position: unset; */
  margin: 0 auto;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  left: unset;
  /* width: unset; */
  margin: 0 auto;
  width: 100%;
}

.previewImage.edgefusion .title {
  font-style: italic;
  font-size: 75px;
  font-weight: 800;
  text-align: center;
  margin: 0 15vw;
  /* max-width: 65vw; */
}

.previewImage.edgefusion .desc {
  text-align: center;
  margin-top: 45px;
  margin: 45px 15vw 0;
  /* max-width: 50vw; */
}

/* MINIMALIST */

.previewImage.minimalist .textOverlay {
  background: unset;
}

.previewImage.minimalist {
  display: flex;
  font-family: "Manrope", serif;
  font-family: inherit;
  height: 60vw;
  max-height: 90vh;
}

.previewImage.minimalist .textOverlay {
  position: unset;
  height: 100%;
  max-height: 100%;
  display: unset;
  background-color: rgba(148, 97, 153, 0.15);
}

.previewImage.minimalist .text {
  position: relative;
  top: 20%;
}

.previewImage.minimalist .desc {
  color: #5d5d5d;
  font-size: 26px;
  margin-top: 35px;
  font-weight: 400;
}

.previewImage.minimalist .title {
  font-size: 54px;
  font-weight: 700;
  color: #212121;
}

.previewImage.minimalist .image img {
  position: unset;
  height: 100%;
  max-height: 100%;
  width: 50vw;
  object-fit: cover;
  max-width: 55vw;
}

@media screen and (max-width: 750px) {
  .previewImage {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
  }

  .textOverlay {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.5)
    );
    border-radius: unset;
    padding: 0;
    margin: 0;
    justify-content: unset;
    width: 100%;
    height: 100%;
  }

  .textOverlay .title {
    /* font-size: 7vw; */
    /* width: 80%; */
    line-height: 1.4;
  }

  .textOverlay .desc {
    line-height: 1.4;
    font-weight: 200;
    /* width: 90%; */
    padding-right: unset;
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }

  .whatsapp {
    z-index: 2;
    position: fixed;
    bottom: 10vh;
    right: 5vw;
  }

  .whatsapp img {
    width: 50px;
    height: 50px;
  }

  /* MIDNIGHTZEN */

  .previewImage.midnightzen {
    height: unset;
    max-height: fit-content;
    min-height: 100vh;
    flex-direction: column;
    border-bottom: 2px solid #757575;
  }

  .previewImage.midnightzen .image {
    order: 0;
  }

  .previewImage.midnightzen .text {
    width: 90%;
  }

  .previewImage.midnightzen .image img {
    width: 90vw;
    height: 45vh;
    margin-bottom: 20px;
    position: unset;
  }

  .previewImage.midnightzen .textOverlay {
    order: 2;
  }

  .previewImage.midnightzen .textOverlay .desc {
    margin-top: 20px;
  }

  /* ELEVATE */

  .previewImage.elevate .text {
    top: 20%;
    width: unset;
  }

  .previewImage.elevate .title,
  .previewImage.elevate .desc {
    font-family: "Nunito Sans", serif;
    max-width: unset;
    margin: 0 5vw;
  }

  .previewImage.elevate .desc {
    margin-top: 20px;
    max-width: unset;
    width: unset;
    /* text-align: start; */
  }

  /* EDGEFUSION */

  .previewImage.edgefusion {
    width: unset;
  }

  .previewImage.edgefusion .textOverlay {
    position: absolute;
  }

  .previewImage.edgefusion .text {
    position: absolute;
    top: 20%;
    left: unset;
    width: 100%;
  }

  .previewImage.edgefusion .title {
    font-size: 28px;
    max-width: 90vw;
  }

  .previewImage.edgefusion .desc {
    max-width: 85vw;
    font-size: 20px;
  }

  /* MINIMALIST */

  .previewImage.minimalist {
    height: unset;
    flex-direction: column;
    max-height: unset;
    background-color: #fff;
    height: calc(100vh - 80px);
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 3vh;
  }

  .previewImage.minimalist .image {
    height: 50%;
    order: 0;
  }

  .previewImage.minimalist .image img {
    width: 100vw;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: unset;
  }

  .previewImage.minimalist .textOverlay {
    background-color: #fff;
    height: 25vh;
    height: unset;
    max-height: unset;
    order: 1;
    position: relative;
  }

  .previewImage.minimalist .text {
    width: unset;
    text-align: center;
    position: unset;
    margin: 0 5vw;
    background-color: #fff;
  }

  .previewImage.minimalist .title {
    position: unset;
    font-size: 28px;
    width: unset;
    margin-top: 4vh;
  }

  .previewImage.minimalist .desc {
    position: unset;
    width: unset;
    font-size: 18px;
    margin-top: 2vh;
    color: #212121;
  }
}
