.query h1 {
  color: var(--primary-color);
  font-size: 26.16px;
  margin-bottom: 20px;
  font-weight: 600;
}

.query {
  padding-right: 4vw;
  overflow: hidden;
}

.query .description {
  width: 28vw;
  display: block;
  text-align: start;
}

.query .description p {
  color: #212121;
  font-size: 17px;
  line-height: 1.4;
  font-weight: 500;
  /* width: fit-content; */
  margin-bottom: 1.5vw;
}

.query form {
  width: 28vw;
}

.query input,
.query textarea,
.query select {
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 1vw 1vw;
  margin-bottom: 15px;
  font-family: inherit;
  /* font-family: "Nunito", sans-serif; */
  width: 100%;
  height: 47.17px;
  font-size: 17.15px;
}

.selectField {
  width: 100%;
  font-size: 17.15px !important;
  height: 47.17px;
  margin-bottom: 20px;
}

/* .countryCodeSelect {
  font-size: 17.15px !important;
  height: 47.17px;
  width: 6vw;
  border-color: #8e8e8e;
} */

.menuItems {
  font-size: 17.15px !important;
}

/* .query .number {
  display: flex;
  gap: 1vw;
  max-width: 28vw;
  min-width: 28vw;
}

.number input {
  width: 21vw;
} */

.query .button {
  display: flex;
  justify-content: center;
}

.query .submit {
  border: none;
  border-radius: 50px;
  background-color: var(--primary-color);
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 45px;
  cursor: pointer;
  font-family: inherit;
  margin-top: 20px;
}

.query .submit:hover {
  opacity: 0.5;
}

.query textarea {
  resize: none;
  /* height: 6vw; */
  height: 93.63px;
}

.query input:focus,
.query textarea:focus,
.query select:focus {
  outline: none;
  border: 1px solid #212121cc;
}

.contactSales {
  display: none;
}

/* MIDNIGHTZEN */

.query.midnightzen .description p {
  color: #fff;
  font-weight: 300;
}

.query.midnightzen input,
.query.midnightzen textarea,
.query.midnightzen .MuiSelect-select {
  background-color: #212121;
  border-color: #fff;
  color: #fff;
}

.query.midnightzen input:focus {
  background-color: #212121;
  color: #fff;
}

.query.midnightzen input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #212121 inset;
  -webkit-text-fill-color: #fff;
}

/* SIMPLEUI */

/* .query.simpleui .submit,
.query.midnightzen .submit {
  background-color: #496ce6;
}

.query.simpleui h1 {
  color: #496ce6;
} */

/* ELEVATE */

/* .query.elevate h1,
.query.midnightzen h1 {
  color: #496ce6;
} */

.query.elevate {
  .submit {
    border-radius: 6px;
    /* background-color: #496ce6; */
  }
}

/* EDGEFUSION */

.query.edgefusion .submit {
  border-radius: unset;
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  /* background-color: #496ce6; */
  font-style: italic;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
}

/* .query.edgefusion h1 {
  color: #496ce6;
} */

/* MINIMALIST */

.query.minimalist button {
  background-color: #946199;
  background-color: var(--primary-color);
  border-radius: unset;
}

.query.minimalist h1 {
  color: #946199;
  color: var(--primary-color);
}

@media (max-width: 750px) {
  .query h1 {
    /* font-size: 2vh; */
    font-size: 20px;
    margin-top: 25px;
  }

  .query .description {
    width: unset;
    margin-top: 1vh;
    margin-bottom: 30px;
  }

  .query .description p {
    font-size: 16px;
    line-height: 1.5;
  }

  .query form {
    width: 90vw;
  }

  .query .number input {
    width: 69vw;
  }

  .query button {
    margin: 0 26%;
  }

  .query .submit {
    font-size: 15.72px;
    /* padding: 10px 40px; */
    padding: 0 60px;
    height: 50px;
  }

  .menuItems {
    font-size: 15px !important;
  }

  .selectField {
    height: 40px;
    font-size: 15px !important;
  }

  .query input {
    padding: 20px 10px;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .query textarea {
    padding-bottom: 10vh;
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    height: unset;
  }

  .contactSales {
    margin-top: 40px;
    border-top: 0.5px solid #bdbddb;
    display: block;
  }

  .contactSales .title {
    font-weight: 700;
    font-size: 20px;
    color: #212121;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .salesDetails {
    display: flex;
    color: #212121;
    gap: 10px;
    font-size: 20px;
    align-items: center;
    margin-bottom: 10px;
  }

  .details {
    font-weight: 400;
    color: #212121;
    font-size: 18px;
  }

  /* MIDNIGHTZEN */

  .query.midnightzen .salesDetails,
  .query.midnightzen .details {
    color: #bbb;
  }

  /* .query.midnightzen .contactSales .title {
    color: #496ce6;
  } */

  /* ELEVATE */

  .query.elevate .submit {
    /* background-color: #496ce6; */
    border-radius: 30px;
  }

  /* EDGEFUSION */

  .query.edgefusion .submit {
    width: 204.98px;
    height: 51.76px;
    border-radius: unset;
    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
    font-size: 17.15px;
    text-transform: capitalize;
    font-style: italic;
    padding: unset;
    /* background-color: #496ce6; */
  }
}
