.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 14px;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
  box-shadow: 0px 3px 6px rgb(207, 204, 204);
}

.header img {
  /* width: 2vw;
  height: 2vw; */
  width: 37px;
  height: 37px;
  object-fit: cover;
  margin-right: 0.85vw;
  margin-left: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 1.95px 1.95px 2.9px;
}

.logoTitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  /* font-size: 1.2vw; */
  text-align: center;
  cursor: pointer;
  font-size: clamp(18px, 1.4vw, 23px);
}

.menu ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px 1vw;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
}

.menu li {
  margin: 0px 0px;
  color: #212121;
  /* font-size: 1vw; */
  font-size: clamp(8px, 1.1vw, 18px);
  font-weight: 500;
  cursor: pointer;
  /* text-align: center; */
  cursor: pointer;
}

.menu li:hover {
  /* color: #496ce6; */
  opacity: 0.5;
}

.menu li.active {
  color: var(--primary-color);
  font-weight: 700;
}

.enquiry,
.signIn {
  font-family: inherit;
  /* font-size: 0.9vw; */
  color: white;
  font-weight: 600;
  background-color: #496ce6;
  background-color: var(--primary-color);
  /* padding: 0.4vw 1.5vw; */
  font-size: clamp(14px, 1.15vw, 18px);
  padding: 8px 35px;
  text-align: center;
  border-radius: 50px;
  border: transparent;
  cursor: pointer;
}

.enquiry:hover,
.signIn:hover {
  /* background-color: #496ce699; */
  opacity: 0.5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.call {
  color: #212121;
  font-style: italic;
  /* font-size: 1vw; */
  font-size: clamp(10px, 1vw, 15px);
  border-left: 1px solid #bdbdbd;
  padding: 0 0.7vw;
}

li.call:hover {
  opacity: unset;
  cursor: auto;
}

.call span {
  display: flex;
  align-items: center;
  font-size: clamp(10px, 1vw, 17px);
  gap: 0.4vw;
  font-weight: 700;
  text-align: start;
}

.menuIcon {
  display: none;
}

/* MIDNIGHT ZEN */

.header.midnightzen {
  background-color: #212121;
  padding: 18px 15px;
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  box-shadow: unset;
  color: #fff;
}

.header.midnightzen .menu li {
  color: #dedede;
  /* font-size: 1.1vw; */
}

.header.elevate .menu li.active {
  color: #fff;
  font-weight: 700;
}

.header.midnightzen img {
  box-shadow: unset;
}

.header.midnightzen .logoTitle p {
  color: #fff;
}

.header.midnightzen .call {
  color: #fff;
}

/* ELEVATE */

.header.elevate {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  padding: 18px 15px;
}

.header.elevate .logoTitle p {
  font-weight: 700;
  /* font-size: 1.4vw; */
}

.header.elevate .menu li {
  color: #212121;
  font-weight: 500;
}

.header.elevate .menu li.active {
  color: #496ce6;
  color: var(--primary-color);
  font-weight: 700;
}

.header.elevate .enquiry,
.header.header.elevate .signIn {
  border-radius: 7.5px;
  font-weight: 600;
}

/* EDGEFUSION */

.header.edgefusion {
  box-shadow: unset;
  padding: 22px 2vw;
  font-family: "Inter", serif;
  font-family: inherit;
}

.header.edgefusion .menu li {
  font-style: italic;
  color: #212121;
  font-weight: 500;
}

.header.edgefusion .menu li.active {
  /* color: #496ce6; */
  font-weight: 700;
}

.header.edgefusion .call {
  line-height: 1.5;
}

.header.edgefusion .enquiry,
.header.edgefusion .signIn {
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  border-radius: unset;
  padding: 10px 38px;
  text-align: center;
  font-style: italic;
  font-weight: 600;
  /* font-size: 1vw; */
}

.header.edgefusion .logoTitle p {
  font-style: italic;
}

.header.edgefusion .menu li.active {
  color: #496ce6;
  color: var(--primary-color);
  font-weight: 700;
}

/* MINIMALIST */

.header.minimalist {
  font-family: "Manrope", serif;
  font-family: inherit;
  padding: 30px 14px;
  box-shadow: unset;
}

.header.minimalist .menu li {
  color: #212121;
  font-weight: 400;
  font-size: clamp(10px, 1.17vw, 18px);
}

.header.minimalist .enquiry,
.header.minimalist .signIn {
  border-radius: unset;
  padding: 10px clamp(30px, 3.75vw, 38px);
  font-weight: 600;
  font-size: clamp(14px, 1vw, 16px);
}

@media screen and (max-width: 750px) {
  .header .menu {
    display: none;
  }

  .header {
    box-shadow: unset;
    position: absolute;
    width: 100%;
    top: 2vh;
    left: 0;
    z-index: 2;
  }

  .header.batchesPage {
    box-shadow: 0px 3px 6px rgb(207, 204, 204);
    position: unset;
    padding: 20px 0;
  }

  .header img {
    width: 40px;
    height: 40px;
    margin-right: 1.5vw;
    margin-left: 3vw;
  }

  .logoTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    color: white;
  }

  .batchesPage .logoTitle {
    color: #212121;
    /* font-size: 20px; */
  }

  .menuIcon {
    display: flex;
    align-items: center;
    padding-right: 2.5vh;
    color: #fff;
    cursor: pointer;
  }

  .batchesPage .menuIcon {
    color: #212121;
  }

  /* MIDNIGHTZEN */
  .header.header.midnightzen {
    top: 0;
    box-shadow: unset;
    position: unset;
  }

  .header.midnightzen img {
    width: 30px;
    height: 30px;
  }

  .header.midnightzen.batchesPage .menuIcon {
    color: #fff;
  }

  .header.elevate .logoTitle p {
    font-weight: 600;
  }

  .header.elevate .logoTitle img {
    width: 9.5vw;
    margin-right: 4vw;
    height: 9.5vw;
  }

  /* EDGEFUSION */

  .header.edgefusion {
    padding-top: 1vh;
  }

  .header.edgefusion .logoTitle img {
    width: 10vw;
    margin-right: 3vw;
    height: 10vw;
    margin-left: 4vw;
  }

  .header.edgefusion .logoTitle p {
    /* font-size: 6vw; */
    font-weight: 700;
  }

  /* MINIMALIST */

  .header.minimalist {
    background-color: #fff;
    margin-top: 0;
    position: relative;
    top: 0;
    padding: 20px 10px;
  }

  .header.minimalist .logoTitle p {
    color: #212121;
    margin-left: 6px;
    font-weight: 700;
  }

  .header.minimalist .menuIcon {
    color: #212121;
  }
}
