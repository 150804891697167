.titleHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vh 0;
}

.titleHeader h2 {
  font-size: var(--section-title-font-size);
  font-weight: 700;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.batches.simpleui {
  font-family: "Poppins", serif;
  font-family: inherit;
  width: 70vw;
  margin: 0 auto 80px;
  max-width: 70vw;
}

.showAll {
  position: absolute;
  right: 15%;
  font-size: 20px;
  font-family: inherit;
  font-weight: 400;
  color: var(--primary-color);
}

.noShowAll {
  display: none;
}

.batchList {
  margin: 0 auto;
  gap: 2vw;
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.simpleui .batchList {
  margin: 0 auto;
  width: 70vw;
  gap: 2vw;
  height: 100%;
}

.batchContent,
.batchFullContent {
  padding: 0 1.5vw;
  display: flex;
  align-self: stretch;
  flex-direction: column;
}

.batchCard .batchContent .title,
.batchCard .batchFullContent .title {
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #496ce6;
  border-color: var(--primary-color);
  margin-top: 0.3vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 2.5;
}

.batchContent .desc,
.batchFullContent .desc {
  order: 2;
  font-size: 15px;
  /* width: 95%; */
  margin-top: 1vw;
  z-index: 2;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.image {
  width: 100%;
  object-fit: cover;
  min-height: 30vh;
  max-height: 30vh;
  border-radius: 15px 15px 0 0;
}

.simpleui .batchCard {
  width: 22vw;
}

.batchCard {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;

  border-radius: 15px;
  width: 25vw;
  max-height: 100%;
  min-height: 100%;
  position: relative;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.simpleui .batchFull {
  min-width: 22vw;
  max-width: 22vw;
}

.batchFull {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 25vw;
  max-width: 25vw;
  min-height: 30vh;
  max-height: 30vh;
  font-size: 100px;
  position: absolute;
  top: 0;
  z-index: 2;
  transform: translateY(0%);
  color: #fff;
  background-color: rgb(0, 0, 0, 0.5);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.batchFull .text {
  font-size: 16px;
  margin-top: 15px;
}

.icon {
  color: #496ce6;
  color: var(--primary-color);
}

.availableSeats {
  color: #ef5350;
  /* font-size: 1.3vw; */
  font-weight: 500;
  margin-bottom: 11.5vh;
}

.batchPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-right: 1vw; */
  gap: 5px;
}

.originalPrice {
  color: #bdbdbd;
  text-decoration: line-through;
  font-size: 15px;
  font-weight: 700;
}

.discountPrice {
  font-size: 22px;
  font-weight: 700;
}

.aboutBatch {
  display: none;
}

.batchDetails {
  display: flex;
  flex-direction: column;
}

.aboutBatchMobile {
  order: 1;
  display: block;
  border-bottom: 1px solid #496ce6;
  border-color: var(--primary-color);
}

.batchCard .bookNowBtn {
  border-radius: 50px;
  border: none;
  font-weight: normal;
  color: white;
  background-color: #496ce6;
  background-color: var(--primary-color);
  padding: 12px 30px;
  font-size: 15.5px;
  font-weight: 500;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  /* margin-left: 1vw;
  margin-right: 1.5vw; */
}

.days {
  display: flex;
  margin: 6px 0 5px;
}

.day {
  border-right: 2px solid #bdbdbd;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.day:first-of-type {
  padding-left: 0;
}

.day:last-of-type {
  border-right: unset;
}

.classDetails {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.sessionFrequency {
  background-color: #f5f5f5;
  width: fit-content;
  font-size: 16.16px;
  font-weight: 500;
  padding: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.onetime.day {
  border-right: none;
  margin: 6px 0 5px;
}

.monthly.day {
  border-right: none;
  margin: 6px 0 5px;
}

.time,
.duration,
.mode {
  background-color: #f5f5f5;
  padding: 0.2vw;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.5vw;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;
}

.availableSeats {
  padding-left: 20px;
  font-size: 15px;
  padding-right: 1.5vw;
  margin-top: 17.5px;
  padding-bottom: 20px;
  font-weight: 700;
}

.pricing {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  border-top: 1px solid #bdbdbd;
  margin: 0.5vw auto 1vw;
  padding-top: 1vw;
  position: absolute;
  justify-self: flex-end;
  align-self: flex-end;
  bottom: 1px;
}

.batchesPage.simpleui .titleHeader {
  display: none;
}

.batchesPage.simpleui {
  margin-top: 160px;
  margin-bottom: 30px;
}

.batchList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.batches.batchesPage .titleHeader {
  font-weight: 700;
  font-size: 32px;
}

/* .batchCard {
  height: unset;
} */

/* SIMPLEUI */

/* .batches.simpleui .title {
  border-color: #496ce6;
}

.batches.simpleui .icon,
.batches.simpleui .showAll {
  color: #496ce6;
}

.batches.simpleui .aboutBatchMobile {
  border-color: #496ce6;
}

.batches.simpleui .bookNowBtn {
  background-color: #496ce6;
} */

.batches.simpleui.batchesPage .loaderWrapper {
  margin-top: 160px;
}

/* MIDNIGHTZEN */

.batches.midnightzen {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  color: #fff;
  background-color: #000;
  padding-bottom: 50px;
  border-bottom: 1px solid #757575;
}

.batches.midnightzen .loaderWrapper {
  background-color: #000;
}

.batches.midnightzen .showAll {
  /* color: #496ce6; */
  right: 10%;
}

/* .batches.midnightzen .icon {
  color: #496ce6;
} */

/* .batches.midnightzen .title,
.batches.midnightzen .aboutBatchMobile {
  border-color: #496ce6;
} */

/* .batches.midnightzen .bookNowBtn {
  background-color: #496ce6;
} */

.batches.midnightzen .batchCard .sessionFrequency {
  background-color: #424242;
}

.batches.midnightzen .titleHeader h2 {
  color: #fff;
}

.batches.midnightzen .batchCard {
  background-color: #212121;
  font-family: "Poppins", sans-serif;
  font-family: inherit;
}

.batches.midnightzen .originalPrice {
  color: #757575;
}

.batches.midnightzen .discountPrice {
  color: #fff;
}

.batches.midnightzen .time,
.batches.midnightzen .duration,
.batches.midnightzen .mode {
  background-color: #424242;
}

.batches.midnightzen .batchContent .desc,
.batches.midnightzen .batchFullContent .desc {
  font-family: "Nunito Sans", serif;
  color: #fff;
}

.batches.batchesPage.midnightzen {
  border: none;
}

.midnightzen dialog::backdrop {
  background-color: rgba(255, 255, 255, 0.27);
}

/* ELEVATE */

.batches.elevate {
  font-family: "Nunito", sans-serif;
  font-family: inherit;
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 2.5vw;
  border-bottom: 1px solid #bdbdbd;
}

.batches.elevate .titleHeader {
  justify-content: flex-start;
  padding: 3vw 0;
}

.batches.elevate .batchCard {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;

  font-family: "Poppins", sans-serif;
  font-family: inherit;
}

.batches.elevate .batchCard .image {
  border-radius: 20px 20px 0 0;
}

.batches.elevate .batchCard:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.batches.elevate .showAll {
  font-weight: 700;
  right: 10%;
  /* color: #496ce6; */
}

/* .batches.elevate .icon {
  color: #496ce6;
} */

/* .batches.elevate .aboutBatchMobile {
  border-color: #496ce6;
} */

.batches.elevate .bookNowBtn {
  border-radius: 12px;
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  /* background-color: #496ce6; */
}

.batches.elevate .title {
  font-weight: 600;
  /* border-color: #496ce6; */
}

.batches.elevate .desc {
  color: #757575;
  font-weight: 400;
}

.batches.elevate .day {
  font-weight: 500;
  color: #212121;
}

.batches.elevate .sessionFrequency {
  font-weight: 500;
}

.batches.elevate .time {
  font-weight: 500;
  border-radius: 3px;
}

.batches.elevate .duration {
  border-radius: 3px;
  font-weight: 500;
}

.batches.elevate .mode {
  border-radius: 3px;
  font-weight: 500;
}

.batches.elevate .originalPrice {
  font-weight: 600;
}

.batches.elevate .discountPrice {
  font-weight: 600;
}

.batches.elevate .availableSeats {
  font-weight: 700;
  color: #ef5350;
}

.batches.elevate.batchesPage .batchCard {
  margin-top: unset;
}

.batches.elevate.batchesPage .titleHeader {
  color: #212121;
  font-size: 32px;
  font-weight: 700;
}

/* EDGEFUSION */

.batches.edgefusion {
  font-family: "Inter", sans-serif;
  font-family: inherit;
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 5vh;
  border-bottom: 1px solid #bdbdbd;
}

.batches.edgefusion .titleHeader {
  /* font-size: 1.25vw; */
  font-style: italic;
}

.batches.edgefusion .showAll {
  /* font-size: 1.1vw; */
  font-weight: 700;
  right: 10%;
  font-family: "Poppins", sans-serif;
  font-family: inherit;
}

.batches.edgefusion .title {
  font-style: italic;
  font-weight: 700;
  /* font-size: 1.2vw; */
}

.batches.edgefusion .batchCard {
  border-radius: 0;
}

.batches.edgefusion .batchCard .image {
  border-radius: unset;
}

.batches.edgefusion .desc {
  color: #757575;
}

.batches.edgefusion .bookNowBtn {
  border-radius: unset;
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  font-style: italic;
  padding: 0.7vw 3vw;
  text-transform: capitalize;
}

.batches.edgefusion .batchFull {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

/* .batches.edgefusion .icon {
  color: #496ce6;
} */

/* .batches.edgefusion .aboutBatchMobile,
.batches.edgefusion .title {
  border-color: #496ce6;
} */

/* .batches.edgefusion .bookNowBtn {
  background-color: #496ce6;
} */

/* .batches.edgefusion .showAll {
  color: #496ce6;
} */

/* MINIMALIST */

.batches.minimalist {
  font-family: "Manrope", serif;
  font-family: inherit;
  padding: 0 10%;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 5vh;
  margin-bottom: 3.5vh;
}

.batches.minimalist ul {
  width: unset;
}

.batches.minimalist .batchCard .bookNowBtn {
  border-radius: unset;
}

.batches.minimalist .titleHeader {
  justify-content: flex-start;
}

.batches.minimalist .showAll {
  /* color: #946199; */
  text-decoration: underline;
  right: 10%;
}

.batches.minimalist .batchCard {
  border-radius: unset;
  box-shadow: unset;
  /* border: 1px solid #946199; */
}

.batches.minimalist img {
  border-radius: unset;
}

.batches.minimalist .batchFull {
  border-radius: unset;
}

@media screen and (max-width: 750px) {
  .batchList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: unset;
  }

  .batchesPage .titleHeader {
    display: block;
    text-align: center;
  }

  /* .batchesPage {
    margin-top: 110px;
  } */

  .batches.simpleui {
    min-width: unset;
    max-width: unset;
    width: unset;
  }

  .simpleui .batchList {
    width: unset;
  }

  .simpleui .batchCard {
    min-width: 90vw;
    max-width: 90vw;
  }

  .simpleui .batchFull {
    min-width: 90vw;
    max-width: 90vw;
  }

  .availableSeats {
    margin-bottom: unset;
  }

  .batchCard {
    width: 90vw;
    max-height: unset;
    min-height: unset;
    margin: 0 auto;
  }

  .day {
    font-size: 13px;
  }

  .batches .batchCard .title {
    font-size: 18px;
    padding: 0 0;
    margin: 10px 0 0;
    line-height: 2;
  }

  .batches .sessionFrequency {
    font-size: 14px;
  }

  .batchContent,
  .batchFullContent {
    padding: 0 20px;
  }

  .time,
  .duration,
  .mode {
    font-size: 14px;
    padding: 5px 5px;
    font-weight: 600;
  }

  .batches .desc {
    font-size: 14px;
    margin-top: 10px;
  }

  .availableSeats {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .titleHeader {
    font-size: 25px;
    padding: 3vh 0 3vh;
    margin: 0 2vw;
    font-weight: 700;
  }

  .showAll {
    right: 5%;
  }

  .pricing {
    margin-top: 10px;
    position: unset;
    padding: 12px 10px;
  }

  .batchPrice {
    gap: unset;
  }

  .originalPrice {
    font-size: 13px;
    font-weight: 600;
  }

  .discountPrice {
    font-size: 20px;
  }

  .batchCard .bookNowBtn {
    font-size: 15px;
    font-weight: 600;
    padding: 12px 50px;
  }

  .batchFull {
    min-width: 90vw;
    max-width: 90vw;
  }

  .batchesPage {
    margin-top: unset;
    /* padding: 2vh 0; */
  }

  .batches.batchesPage .titleHeader h2 {
    font-weight: 700;
    font-size: 24px;
  }

  .showAll {
    font-size: 15px;
  }

  .batches.simpleui.batchesPage {
    margin-top: 130px;
  }

  .batches.simpleui .showAll {
    color: #496ce6;
  }

  /* MIDNIGHTZEN */

  .batches.midnightzen .showAll {
    font-size: 15px;
    right: 5%;
  }

  /* ELEVATE */
  .batches.elevate {
    width: 90vw;
    font-family: "Nunito Sans", serif;
    font-family: inherit;
    border-bottom: unset;
  }
  .batches.elevate .batchCard {
    border-radius: 15px;
  }

  .batches.elevate .batchCard .image {
    border-radius: 15px 15px 0 0;
  }

  .batches.elevate .titleHeader {
    justify-content: center;
    /* font-size: 3vh; */
    margin: 2vh 0;
  }

  .batches.elevate .originalPrice {
    font-size: 13px;
  }

  .batches.elevate .discountPrice {
    font-size: 20px;
  }

  .batches.elevate .desc {
    font-size: 14px;
    color: #424242;
  }

  .batches.elevate .availableSeats {
    font-size: 13px;
    font-weight: 700;
    padding-bottom: unset;
  }

  .batches.elevate .sessionFrequency {
    font-size: 14px;
  }

  .batches.elevate .title {
    font-size: 16px;
  }

  .batches.elevate .showAll {
    right: 5%;
    font-family: inherit;
  }

  .batches.elevate .batchCard .bookNowBtn {
    border-radius: 10px;
  }

  /* EDGEFUSION */

  .batches.edgefusion {
    width: unset;
    border: unset;
    padding-bottom: unset;
  }

  .batches.edgefusion .titleHeader {
    /* font-size: 20px; */
    font-style: normal;
    align-items: flex-end;
    padding-top: unset;
    padding-bottom: 4vh;
  }

  .batches.edgefusion .showAll {
    right: 5%;
  }

  .batches.edgefusion .showAll {
    /* font-size: 17px; */
    font-style: normal;
  }

  .batches.edgefusion .batchCard {
    border-radius: 20px;
    height: unset;
  }

  .batches.edgefusion .batchCard .image {
    border-radius: 20px 20px 0 0;
  }

  .batches.edgefusion .batchFull {
    border-radius: 20px 20px 0 0;
  }

  .batches.edgefusion .batchCard .title {
    /* font-size: 22px; */
    font-weight: 700;
    font-style: normal;
  }

  .batches.edgefusion .sessionFrequency {
    font-weight: 600;
    /* font-size: 17px; */
  }

  .batches.edgefusion .batchCard .desc {
    /* font-size: 18px; */
    color: #212121;
  }

  .batches.edgefusion .mode,
  .batches.edgefusion .time,
  .batches.edgefusion .duration {
    font-weight: 600;
    /* font-size: 16.5px; */
    gap: 8px;
    padding: 5px 7px;
  }

  .batches.edgefusion .availableSeats {
    /* font-size: 15px; */
    margin-top: 20px;
    width: 95%;
    padding-bottom: 5px;
  }

  .batches.edgefusion .batchPrice {
    margin-right: 10px;
  }

  .batches.edgefusion .bookNowBtn {
    font-size: 15px;
    clip-path: polygon(7.5% 0%, 100% 0, 92.5% 100%, 0 100%);
    padding: 0;
    height: 41.41px;
    width: 45vw;
    max-width: 160px;
    font-weight: 600;
  }

  .batches.edgefusion .pricing {
    padding: 10px 10px 5px 35px;
  }

  .batches.edgefusion.batchesPage .titleHeader {
    /* font-size: 24px; */
    font-weight: 700;
    padding-top: 5vh;
  }

  /* MINIMALIST */

  .batches.minimalist {
    padding: 0;
    padding-bottom: 5vh;
    font-family: "Inter", serif;
  }

  .batches.minimalist .titleHeader {
    text-align: center;
    justify-content: center;
  }

  .batches.minimalist .showAll {
    right: 5%;
  }
}
