.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6vw 1.6vw;
  box-shadow: 0px 3px 6px rgb(207, 204, 204);
  background-color: white;

  /* position: sticky; */
  top: 0;
  z-index: 10;
}

.dashboard-header .brand-logo img {
  width: 2.5vw;
  height: 2.5vw;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-right: 0.55vw;
  margin-left: 5px;
  border-radius: 8px;
}

.dashboard-header .brand-logo {
  display: flex;
  align-items: center;
}

.dashboard-header .brand-logo span {
  font-weight: 650;
  font-size: 1.5vw;
}

.dashboard-header .contact-us-details {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 1vw;
  color: #212121;
}

.dashboard-header .contact-us-details .call-at {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.35vw;

  padding-right: 1.5vw;
  border-right: 0.5px solid #212121cc;
}

.dashboard-header .call-at span {
  font-style: italic;
  font-size: 0.95vw;
  font-weight: 600;
}

.dashboard-header .call-at .phone-number {
  font-size: 1.3vw;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5vw;
}

.dashboard-header .call-at .phone-number span {
  font-size: 1.15vw;
  font-weight: 800;
}

.dashboard-header .contact-us-details .customer-image {
  margin-left: 1.5vw;
  height: 3.3vw;
  width: 3.3vw;
  border-radius: 50%;
  cursor: pointer;
}

/* .dashboard-header .account {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6vw;
  font-weight: 600;
  color: white;
  background-color: #496ce6;
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  border: transparent;
  cursor: pointer;
} */

@media screen and (max-width: 750px) {
  .dashboard-header {
    padding: 4.3vw 3vw;
    box-shadow: unset;
  }

  .dashboard-header .brand-logo img {
    width: 9.5vw;
    height: 9.5vw;
    margin-right: 1.5vw;
  }

  .dashboard-header .brand-logo span {
    font-weight: 700;
    font-size: 5.65vw;
  }

  .dashboard-header .contact-us-details .call-at {
    display: none;
  }

  .dashboard-header .contact-us-details .customer-image {
    margin-left: 2vw;
    height: 9.7vw;
    width: 9.7vw;
  }
}
