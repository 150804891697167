.invoicesList {
  /* width: auto; */
  border-collapse: collapse;
  font-size: 1vw;
  padding: 0 12vw 1.5vw;
  color: #424242;
}

.invoicesList.recent {
  padding: unset;
  font-size: 0.95vw;
  /* font-size: 17.5px; */
}

.invoicesList .headerRow,
.invoicesList .dataRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.25fr 2fr;
  align-items: center;
}

.invoicesList.recent .headerRow,
.invoicesList.recent .dataRow {
  grid-template-columns: 1fr 1fr 0.6fr 1.4fr 1fr;
  /* grid-template-columns: 0.6fr 0.7fr 1fr 0.8fr 1fr; */
  width: 100%;
}

.invoicesList.recent .headerRow .paymentBtn {
  text-align: right;
  width: 95%;
}

.invoicesList .headerRow {
  font-weight: 600;
  font-size: 1.02vw;
  text-align: left;
  background-color: #f5f5f5;
  color: #212121;
}

.invoicesList.recent .headerRow {
  background-color: unset;
  font-size: 0.97vw;
  /* font-size: 18.5px; */
}

.invoicesList .dataRow {
  border-bottom: 1px solid #e0e0e0;
}

.invoicesList .tableHeader,
.invoicesList .tableData {
  padding: 0.8vw 1vw;
}

.invoicesList.recent .tableHeader,
.invoicesList.recent .tableData {
  padding: 0.7vw 0.6vw;
}

.invoicesList .payment {
  display: flex;
  justify-content: start;
  align-items: center;
}

.invoicesList .payNow,
.invoicesList .downloadReceipt,
.invoicesList .pending {
  font-family: inherit;
  font-size: 0.9vw;
  font-weight: 700;
  padding: 0.45vw 1vw;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  width: 62%;
}

.invoicesList.recent .payNow,
.invoicesList.recent .downloadReceipt,
.invoicesList.recent .pending {
  font-size: 0.82vw;
  /* font-size: 15px; */
  padding: 0.35vw;
  padding: 0.5vw;
  /* width: 60%; */
  width: 100%;
  /* margin: 0 auto; */
}

.invoicesList.recent .mop {
  text-align: center;
}

.invoicesList .payNow {
  background-color: #0c66e4;
  color: white;
  border: none;
}

.invoicesList .downloadReceipt {
  background-color: white;
  color: #0c66e4;
  border: 2px solid #007bff;
}

.invoicesList .pending {
  background-color: #ffb700;
  color: #fff;
  border: none;
}

.invoicesList .pending:hover {
  background-color: #df9c00;
}

.invoicesList.recent .downloadReceipt {
  border: 1.5px solid #007bff;
}

.invoicesList .payNow:hover {
  background-color: #0056b3;
}

.invoicesList .downloadReceipt:hover {
  background-color: #c4c1c133;
}
