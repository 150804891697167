.services.simpleui {
  font-family: "Poppins", serif;
  font-family: inherit;
}

.titleHeader {
  text-align: center;
  margin: 4vh 2vw 4vh;
  padding-top: 3vh;
  /* font-size: 30px; */
  color: #212121;
}

.titleHeader h2 {
  font-weight: 700;
  /* font-size: 28px; */
  font-size: var(--section-title-font-size);
}

.servicesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
}

.serviceCard {
  display: flex;
  width: 70vw;
  /* height: 240px; */
  height: 160px;
  box-shadow: 3px 3px 10px 3px #dedede;
  border-radius: 20px;
  position: relative;
}

.serviceCard img {
  min-width: 25%;
  max-width: 25%;
  object-fit: cover;
  margin-right: 2vw;
  height: 100%;
  border-radius: 20px 0 0 20px;
}

.serviceContent {
  display: flex;
  flex-direction: column;
}

.serviceContent .title {
  font-weight: 700;
  /* font-size: 22px; */
  font-size: var(--card-title-font-size);
  color: #212121;
  margin-bottom: 0.5vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.content {
  padding: 1.2vw 2vw 15px 0;
}

.content .desc {
  color: #757575;
  /* font-size: 18px; */
  font-size: var(--card-desc-font-size);
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.readMore {
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  font-weight: 600;
}

.simpleui .readMore {
  color: #496ce6;
  color: var(--primary-color);
}

/* EDGEFUSION */

.services.edgefusion {
  font-family: "Inter", sans-serif;
  font-family: inherit;
  border-bottom: 1px solid #bdbdbd;
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 5vh;
}

.services.edgefusion .titleHeader {
  font-style: italic;
  font-weight: 700;
}

.serviceCard.edgefusion {
  justify-content: space-between;
  border-radius: unset;
  height: 270px;
  width: 80vw;
  padding-right: 5vw;
}

.serviceCard.edgefusion img {
  min-width: 40%;
  max-width: 40%;
  border-radius: unset;
  margin-right: 0;
  height: 100%;
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0) 100%
  );
}

.serviceCard.edgefusion .title {
  /* font-size: 18px; */
  text-align: center;
  margin-bottom: 22px;
  margin-top: 20px;
}

.serviceCard.edgefusion .desc {
  /* font-size: 14px; */
  text-align: center;
  /* margin-bottom: 1vh; */
  margin-bottom: 8px;
}

.serviceCard.edgefusion .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.serviceCard.edgefusion .readMore {
  /* font-size: 14px; */
  bottom: 48px;
  /* right: 23%; */
  transform: translateX(-50%);
  background-color: #496ce6;
  background-color: var(--primary-color);
  color: #fff;
  padding: 12.8px 46px;
  font-family: "Inter", sans-serif;
  font-family: inherit;
  font-style: italic;
  clip-path: polygon(8% 0%, 100% 0, 92% 100%, 0 100%);
}

.serviceCard.edgefusion .readMore svg {
  display: none;
}

.services.edgefusion .servicesList li:nth-child(2n) .serviceCard {
  display: flex;
  flex-direction: row-reverse;
  padding-right: unset;
}

.services.edgefusion .servicesList li:nth-child(2n) .serviceCard img {
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
}

.services.edgefusion .servicesList li:nth-child(2n) .serviceCard .content {
  /* transform: translateX(10%); */
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.services.edgefusion .servicesList li:nth-child(2n) .serviceCard .readMore {
  /* left: 20.5%; */
  transform: translateX(-50%);
  right: unset;
}

@media screen and (max-width: 750px) {
  .serviceCard {
    width: 90vw;
    flex-direction: column;
    height: fit-content;
  }

  .serviceCard img {
    min-width: 100%;
    max-width: 100%;
    height: 55%;
    border-radius: 20px 20px 0 0;
  }

  .content {
    padding: 15px 20px 27px;
  }

  .serviceContent .title {
    color: #424242;
    /* font-size: 20px; */
    font-weight: 700;
  }

  .serviceContent .desc {
    /* font-size: 18px; */
    font-weight: 400;
    margin-bottom: 14px;
    color: #757575;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .readMore {
    bottom: 15px;
    font-weight: 600;
    font-size: 14px;
  }

  /* EDGEFUSION */

  .services.edgefusion {
    width: unset;
    border: none;
  }

  .services.edgefusion .servicesList li:nth-child(2n) .serviceCard {
    display: flex;
    flex-direction: column;
    left: 0;
  }

  .services.edgefusion .servicesList li:nth-child(2n) .serviceCard img {
    mask-image: none;
    -webkit-mask-image: none;
  }

  .services.edgefusion .servicesList li:nth-child(2n) .serviceCard .readMore {
    left: unset;
    /* right: 21%; */
  }

  .services.edgefusion .titleHeader {
    font-size: 20px;
    font-style: normal;
  }

  .serviceCard.edgefusion {
    width: 90vw;
    padding-right: unset;
    border-radius: 20px;
    height: unset;
  }

  .serviceCard.edgefusion img {
    min-width: 100%;
    max-width: 100%;
    mask-image: none;
    -webkit-mask-image: none;
    height: 30vh;
    border-radius: 20px 20px 0 0;
  }

  .serviceCard.edgefusion .content {
    width: unset;
    padding: 15px 15px;
    padding-bottom: 58px;
  }

  .serviceCard.edgefusion .serviceContent .title {
    margin-top: unset;
    font-size: 20px;
    margin-bottom: unset;
  }

  .serviceCard.edgefusion .serviceContent .desc {
    font-size: 19px;
    padding-top: 5px;
    font-weight: 400;
  }

  .serviceCard.edgefusion .readMore {
    /* padding: 15px 14%; */
    height: 42px;
    width: 45vw;
    min-width: 163px;
    padding: 0;
    bottom: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    clip-path: polygon(7% 0%, 100% 0, 93% 100%, 0 100%);
    /* right: 21%; */
  }
}
