.submissionConfirmationPopup {
  margin: auto auto;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  color: #212121;
  border: none;
  padding: 30px 30px 35px;
  width: 500px;
}

.submissionConfirmationPopup::backdrop {
  background-color: rgba(0, 0, 0, 0.55);
}

.submissionConfirmationPopup {
  border: none;
  background-color: #f5f5f5;
  border-radius: 20px;
}

.check {
  background-color: #3dba3d;
  color: #f5f5f5;
  font-size: 50px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  margin: 0 auto;
}

.title {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 15px;
  font-size: 22px;
  text-align: center;
}

.desc {
  text-align: center;
  font-size: 18px;
  width: 75%;
  line-height: 1.4;
  margin-bottom: 20px;
}

.alignLeft .desc {
  width: 100%;
}

.paymentDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  width: 100%;
}

.alignLeft .dialogContainer {
  background-color: #fff;
}

.alignLeft .paymentDetails {
  background-color: #f5f5f5;
  text-align: left;
  justify-content: flex-start;
  padding-left: 20px;
  padding-top: 5px;
  border-radius: 10px;
  width: 100%;
}

/* .identifier,
.detail {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding-inline-start: 0;
  width: 50%;
  word-break: break-word;
  overflow-wrap: break-word;
} */

.alignLeft .identifier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* align-items: flex-end; */
  padding-inline-start: 0;
  /* width: 50%; */
}

.alignLeft .identifier {
  padding-inline-start: 0;
}

.alignLeft .detail {
  padding-inline-start: 0;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.detail {
  padding-inline-start: 0;
  font-weight: 700;
}

.list span {
  display: block;
  align-self: flex-start;
  text-align: left;
}

.paymentDetails li {
  margin-bottom: 14px;
  font-size: 19px;
}

.alignLeft .paymentDetails li {
  font-size: 15px;
  margin-bottom: 5px;
}

.alignLeft .paymentDetails .textBold {
  font-size: 17px;
  line-height: 1.5;
}

.textBold {
  font-weight: 700;
}

.button {
  border: none;
  color: #424242;
  font-size: 22px;
  display: flex;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 1px 1px 5px 3px #dfdfdf;
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.okButton {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.midnightzen::backdrop {
  background-color: rgba(255, 255, 255, 0.25);
}

.midnightzen .okButton {
  background-color: #212121;
}

.alignLeft .okButton {
  background-color: #fff;
}

.okButton .ok {
  background-color: #0c6ef4;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 10px 60px;
  margin-bottom: 35px;
  cursor: pointer;
  font-size: 15px;
  font-family: inherit;
}

.okButton .ok:hover {
  opacity: 0.5;
}

.table {
  /* text-align: center; */
  margin: 0 auto;
  border-collapse: collapse;
}

.table td {
  border: none;
  padding-bottom: 10px;
}

.table td:nth-child(1) {
  text-align: right;
  padding-right: 20px;
}
.table td:nth-child(2) {
  text-align: left;
}

.midnightzen {
  background-color: #212121;
  color: #fff;
}

.midnightzen .dialogContainer {
  background-color: #212121;
  color: #fff;
}

.midnightzen .button {
  background-color: #424242;
  color: #fff;
  box-shadow: unset;
}

.midnightzen .button {
  background-color: #424242;
  color: #fff;
  box-shadow: unset;
}

.elevate .okButton {
  border-radius: 10px;
}

.edgefusion .okButton .ok {
  width: 204.98px;
  height: 51.76px;
  border-radius: unset;
  padding: unset;
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  font-style: italic;
  font-weight: 700;
  font-size: 17.15px;
}

.midnightzen {
  background-color: #212121;
  color: #fff;
}

.midnightzen .dialogContainer {
  background-color: #212121;
  color: #fff;
}

.midnightzen .button {
  background-color: #424242;
  color: #fff;
  box-shadow: unset;
}

.midnightzen .button {
  background-color: #424242;
  color: #fff;
  box-shadow: unset;
}

.elevate .okButton {
  border-radius: 10px;
}

.edgefusion .okButton .ok {
  width: 204.98px;
  height: 51.76px;
  border-radius: unset;
  padding: unset;
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  font-style: italic;
  font-weight: 700;
  font-size: 17.15px;
}

.minimalist .okButton .ok {
  width: 158px;
  height: 50px;
  border-radius: unset;
  padding: 0;
}

@media screen and (max-width: 750px) {
  .submissionConfirmationPopup {
    width: 85%;
  }
  .dialogContainer {
    width: unset;
  }
  .paymentDetails li {
    font-size: 4vw;
  }
  .desc {
    width: 90%;
  }
}
