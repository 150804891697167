.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4vw;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 1.9vw; */
  font-size: 30px;
  color: #424242;
  font-weight: 500;
}

.app .image {
  /* width: 1.7vw; */
  width: 26.1px;
  height: 26.1px;
  /* margin-right: 0.5vw; */
  margin-right: 6.78px;
}

.app {
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 0 1vw;
}

.tryApp {
  display: flex;
  align-items: center;
}

.bgBlueBtn {
  border: none;
  background-color: #0c66e4;
  color: #fff;
  padding: 12px 50px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  font-family: inherit;
  cursor: pointer;
  transition: 0.3s all ease;
}

.bgBlueBtn:hover {
  background-color: #0c66e499;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.button {
  margin: 1.4vw 0;
}

@media screen and (max-width: 750px) {
  .text {
    /* font-size: 2.1vh; */
    font-size: 22px;
    padding: 0 5vw;
    text-align: center;
    color: #757575;
  }

  .app .image {
    /* width: 2.3vh;
    height: 2.3vh; */
    width: 25px;
    height: 25px;
    margin-right: 0.5vh;
  }

  .app {
    font-weight: 600;
    color: #212121;
    margin: 0 1vh;
  }

  .bgBlueBtn {
    /* font-size: 2vh; */
    font-size: 22px;
    /* padding: 1.5vh 5vh; */
    padding: 15px 50px;
    font-weight: 600;
  }

  .bgBlueBtn:hover {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  }

  .button {
    margin: 2vh 0;
  }
}
