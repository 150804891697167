.serviceModal {
  background-color: white;
  border: none;
  margin: auto;
  border-radius: 20px;
  width: 62%;
  max-width: 900px;
  /* height: 420px; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.serviceModal::-webkit-scrollbar {
  display: none;
}

.serviceModal:focus-visible {
  outline: none;
}

.serviceModal::backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.serviceModalContainer {
  display: flex;
  padding: 30px 22px 30px 30px;
}

.serviceModal .close {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  padding: 4px;
  font-size: 23px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  color: #424242;
}

.close:hover {
  background-color: #e6e3e366;
  color: black;
}

.serviceModal img {
  min-width: 35%;
  max-width: 35%;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0 0 9px #acabab;
  /* margin: 30px 22px 30px 30px; */
}

.serviceModal .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin: 30px 22px 30px 0px; */
  color: #424242;
  padding: 0px 30px 15px 24px;
}

.serviceModal .content .title {
  color: #496ce6;
  color: var(--primary-color);
  /* font-size: 1.6vw; */
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0.5vw;
}

.serviceModal .desc {
  /* font-size: 1vw; */
  font-size: var(--secondary-font-size);
  font-weight: normal;
  line-height: 1.3;
  font-weight: 500;
  text-align: justify;
}

.serviceModal .benefits {
  margin-top: 30px;
  /* margin-bottom: 30px; */
}

.serviceModal .benefits .desc {
  margin-top: 10px;
}

.serviceModal .benefits span {
  font-size: var(--card-title-font-size);
  font-weight: 700;
}

/* SIMPLEUI */
/* .serviceModal.simpleui .title {
  color: #496ce6;
} */

.serviceModal.simpleui {
  font-family: "Poppins", serif;
  width: 80vw;
}

.serviceModal.simpleui img {
  box-shadow: unset;
}

.serviceModal.simpleui .desc,
.serviceModal.simpleui .desc {
  color: #424242;
}

.serviceModal.simpleui::backdrop {
  background-color: rgba(0, 0, 0, 0.475);
}

/* MIDNIGHTZEN */

.serviceModal.midnightzen {
  font-family: "Nunito", sans-serif;
  font-family: inherit;
  background-color: #212121;
}

.serviceModal.midnightzen .title {
  color: #fff;
}

.serviceModal.midnightzen .desc {
  color: #fff;
}

.serviceModal.midnightzen .close {
  box-shadow: none;
  color: #fff;
  background-color: #424242;
  font-size: 25px;
}

.midnightzen::backdrop {
  background-color: rgba(255, 255, 255, 0.27);
}

.serviceModal.midnightzen img {
  box-shadow: unset;
}

.serviceModal.midnightzen .benefits > span {
  color: #fff;
}

.serviceModal.midnightzen .benefits {
  color: #fff;
}

/* ELEVATE */

.serviceModal.elevate {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  border-radius: 15px;
}

/* .serviceModal.elevate .title {
  color: #496ce6;
} */

.serviceModal.elevate::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.serviceModal.simpleui .desc {
  font-weight: 400;
  color: #424242;
}

/* .serviceModal.elevate .close {
  top: 15px;
  right: 15px;
} */

.serviceModal.elevate img {
  box-shadow: unset;
  border-radius: 12px;
}

/* EDGEFUSION */

.serviceModal.edgefusion {
  font-family: "Inter", sans-serif;
  font-family: inherit;
  border-radius: unset;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.serviceModal.edgefusion img {
  box-shadow: unset;
  border-radius: unset;
}

/* .serviceModal.edgefusion .title {
  color: #496ce6;
} */

/* MINIMALIST */

.serviceModal.minimalist {
  font-family: "Manrope", serif;
  font-family: inherit;
  border-radius: unset;
}

.serviceModal.minimalist::backdrop {
  background-color: rgba(0, 0, 0, 0.45);
}

.serviceModal.minimalist .title {
  color: #946199;
  color: var(--primary-color);
}

.serviceModal.minimalist img {
  border-radius: unset;
  box-shadow: unset;
}

@media screen and (max-width: 1100px) {
  .serviceModal img {
    height: 430px;
  }
}

@media screen and (max-width: 750px) {
  .serviceModal {
    height: 75vh;
    width: 90vw;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
    border-radius: 10px;
    padding-bottom: 50px;
  }

  .serviceModal::-webkit-scrollbar {
    display: none;
  }

  .serviceModal img {
    width: 90%;
    height: 40vh;
    box-shadow: unset;
    position: relative;
    border-radius: 8px;
    margin: 0;
    margin-top: 4vw;
    min-width: unset;
    max-width: unset;
  }

  .serviceModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: unset;
  }

  .serviceModal .close {
    position: fixed;
    left: unset;
    z-index: 2;
    font-size: 20px;
    padding: 4px;
    top: 14vh;
    right: 9%;
  }

  .serviceModal.simpleui .close {
    top: 19vh;
    right: 14%;
  }

  .serviceModal .content {
    width: 90%;
    margin: 20px 0;
    padding: 0;
  }

  .serviceModal .content .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .serviceModal .desc {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
    padding-bottom: 0;
  }

  .serviceModal .benefits {
    padding-top: 0;
  }

  .serviceModal .benefits span {
    font-size: 20px;
    padding-bottom: 10px;
  }

  /* EDGEFUSION */

  .serviceModal.edgefusion {
    border-radius: 10px;
  }

  .serviceModal.edgefusion img {
    border-radius: 8px;
  }
}
