.whyUsModal {
  background-color: white;
  border: none;
  margin: auto;
  border-radius: 20px;
  max-width: 50vw;
  min-width: 40vw;
  /* height: 70vh; */
  height: fit-content;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* padding-bottom: 20px; */
}

.whyUsModal::-webkit-scrollbar {
  display: none;
}

.whyUsModal:focus-visible {
  outline: none;
}

.whyUsModal::backdrop {
  background: rgba(0, 0, 0, 0.25);
}

.whyUsModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2vw 2.5vw 2vw 2.5vw;
}

.whyUsModal .close {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  padding: 5px;
  font-size: 25px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #424242;
}

.close:hover {
  background-color: #e6e3e366;
  color: black;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whyUsModal img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
  margin-bottom: 15px;
}

.whyUsModal .title {
  color: #212121;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.whyUsModal .desc {
  color: #212121;
  font-size: var(--secondary-font-size);
  line-height: 1.5;
  font-weight: normal;
  text-align: justify;
}

.whyUsModal.simpleui {
  font-family: "Poppins", serif;
}

/* SIMPLEUI */

.whyUsModal.simpleui {
  width: 42vw;
  max-width: 42vw;
}

.whyUsModal.simpleui .desc,
.whyUsModal.edgefusion .desc {
  color: #424242;
}

.whyUsModal.simpleui::backdrop {
  background-color: rgba(0, 0, 0, 0.25);
}

/* MIDNIGHTZEN */

.whyUsModal.midnightzen {
  font-family: "Nunito", sans-serif;
  background-color: #212121;
  /* text-align: center; */
}

.whyUsModal.midnightzen .title {
  color: #fff;
}

.whyUsModal.midnightzen .desc {
  color: #fff;
}

.whyUsModal.midnightzen .close {
  box-shadow: none;
  color: #fff;
  background-color: #424242;
  /* font-size: 20px;
  padding: 7px; */
}

.midnightzen::backdrop {
  background-color: rgba(255, 255, 255, 0.27);
}

/* EDGE FUSION */

.whyUsModal.edgefusion {
  font-family: "Inter", sans-serif;
  border-radius: unset;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* text-align: center; */
}

.whyUsModal.edgefusion .close {
  color: #424242;
}

.whyUsModal.edgefusion .desc {
  color: #424242;
  /* font-size: 20px; */
}

/* ELEVATE */

.whyUsModal.elevate {
  font-family: "Nunito Sans", serif;
  width: 45vw;
  max-width: 45vw;
}

.whyUsModal.elevate .title {
  margin-bottom: 15px;
}

/* .whyUsModal.elevate .desc {
  text-align: start;
} */

.whyUsModal.elevate .modalContent {
  align-items: flex-start;
}

/* MINIMALIST */

.whyUsModal.minimalist {
  font-family: "Manrope", serif;
  border-radius: unset;
}

.whyUsModal.minimalist .whyUsModalContainer {
  justify-content: flex-start;
  align-items: flex-start;
}

.whyUsModal.minimalist .modalContent {
  align-items: flex-start;
}

@media screen and (max-width: 750px) {
  .whyUsModal {
    height: 75vh;
    width: 90vw;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
    border-radius: 10px;
    max-width: unset;
  }

  .whyUsModal::-webkit-scrollbar {
    display: none;
  }

  .whyUsModal img {
    width: 80px;
    height: 80px;
    box-shadow: unset;

    margin: 16px 10px 18px;
  }

  .whyUsModalContainer {
    position: relative;
    padding: 0 10px;
  }

  .modalContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  .whyUsModal .close {
    position: fixed;
    top: 14.5%;
    left: 85%;
    right: unset;
    font-size: 20px;
    padding: 4px;
  }

  .whyUsModal .title {
    font-size: 20px;
    margin: 0 10px 16px;
    color: #424242;
  }

  .whyUsModal .desc {
    margin: 0 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
    color: #424242;
    text-align: center;
  }

  /* SIMPLEUI */

  .whyUsModal.simpleui {
    width: 90vw;
    max-width: 90vw;
    box-shadow: unset;
  }

  .whyUsModal.simpleui::backdrop {
    background-color: rgba(0, 0, 0, 0.45);
  }

  .whyUsModal.simpleui .title {
    font-weight: 600;
  }

  /* MIDINGHTZEN */

  .whyUsModal.midnightzen::backdrop {
    background-color: unset;
  }

  /* ELEVATE */
  .whyUsModal.elevate .desc {
    text-align: start;
  }

  /* EDGEFUSION */

  .whyUsModal.edgefusion .desc {
    font-size: 18px;
  }

  .whyUsModal.edgefusion {
    border-radius: 15px;
  }

  /* ELEVATE */

  .whyUsModal.elevate {
    width: 90vw;
    max-width: 90vw;
  }
}
