.gallery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  padding: 2vw 10% 2vw 10%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery h2 {
  text-align: center;
  font-size: var(--section-title-font-size);
  font-weight: bold;
  margin-bottom: 4vh;
}

.carouselWrapper {
  position: relative;
  width: 80vw;
  height: 36vw;
  /* min-height: 420px; */
}

.carouselCard {
  width: 100%;
  height: 35vw;
  /* min-height: 400px; */
  display: flex;
  flex: 1;
  position: absolute;
  box-shadow: 0px 0px 7px 0px rgba(165, 162, 162, 0.22);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  left: 100%;
  border-radius: 12px;
}

.carouselCardActive {
  opacity: 1;
  /* transform: scale(1); */
  pointer-events: visible;
  left: 0;
}

.carouselCard img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* top: 17.5vw; */
  top: 50%;
}

.leftArrow,
.rightArrow {
  transform: translate(0, -50%);
  width: 61px;
  height: 61px;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.leftArrow {
  margin-left: 2vw;
  font-size: 30px;
}

.rightArrow {
  margin-right: 2vw;
  font-size: 30px;
}

.carouselPagination {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 10px;
}

.paginationDot {
  height: 12px;
  width: 12px;
  background-color: #bfb4b4;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 3vw;
}

.paginationDot:hover {
  transform: scale(1.2);
}

.paginationDotActive {
  background-color: #496ce6;
  background-color: var(--primary-color);
}

.nextImage {
  display: none;
}

.prevImage {
  display: none;
}

/* MIDNIGHTZEN */

.gallery.midnightzen {
  background-color: #000;
  color: #fff;
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  padding: 25px 10% 50px;
  border-bottom: 1px solid #757575;
  height: unset;
}

.gallery.midnightzen .paginationDot {
  display: none;
}

.gallery.midnightzen .carouselWrapper {
  height: 38vw;
}

.gallery.midnightzen .carouselCard {
  padding-bottom: 10px;
}

.gallery.midnightzen .leftArrow,
.gallery.midnightzen .rightArrow {
  /* background-color: #496ce6; */
  font-size: 23px;
  display: flex;
  width: 53px;
  height: 53px;
}

.gallery.midnightzen .arrow {
  top: 38vw;
  justify-content: center;
  gap: 30px;
  /* left: 50%; */
  align-items: center;
  /* height: 30px; */
}

/* MINIMALIST */

.gallery.minimalist {
  font-family: "Manrope", serif;
  font-family: inherit;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 12vh;
  margin-bottom: 3.5vh;
}

.gallery.minimalist .titleHeader h2 {
  text-align: start;
}

.gallery.minimalist .carouselCard {
  max-height: 100vh;
  height: 60vw;
}

.gallery.minimalist .carouselWrapper {
  max-height: 100vh;
  height: 61vw;
}

.gallery.minimalist .carouselCard img {
  border-radius: unset;
}

.gallery.minimalist .leftArrow,
.gallery.minimalist .rightArrow {
  background-color: #f5f5f5;
  color: #946199;
  color: var(--primary-color);
}

.gallery.minimalist .leftArrow {
  margin-left: 0;
  transform: translate(-50%, -50%);
}

.gallery.minimalist .rightArrow {
  margin-right: 0;
  transform: translate(50%, -50%);
}

.gallery.minimalist .paginationDotActive {
  background-color: #946199;
  background-color: var(--primary-color);
}

@media screen and (max-width: 750px) {
  .gallery {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2vh 0 5vh 0;
    height: 66vh;
    width: 100%;
  }

  .gallery h2 {
    text-align: center;
    /* font-size: 3vh; */
    font-weight: bold;
    margin-bottom: 3vh;
  }

  .carouselCard {
    height: 90%;
  }

  .carouselWrapper {
    height: 100%;
    width: 90vw;
  }

  .carouselPagination {
    position: absolute;
    top: 95%;
    transform: translateX(-60%);
  }

  .paginationDot {
    width: 1.2vh;
    height: 1.2vh;
    margin-bottom: unset;
  }

  .arrow {
    top: 26.5vh;
  }

  .rightArrow,
  .leftArrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .gallery.midnightzen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2vh 0 5vh 0;
    height: 83vh;
    margin: unset;
    width: 100%;
    border-bottom: 2px solid #757575;
  }

  .gallery.midnightzen .carouselCard img {
    height: 60vh;
  }

  .gallery.midnightzen .leftArrow,
  .gallery.midnightzen .rightArrow {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  .gallery.midnightzen .arrow {
    top: 66vh;
  }

  /* MINIMALIST */

  .gallery.minimalist {
    height: unset;
    padding-bottom: 10vh;
    font-family: "Inter", serif;
  }

  .gallery.minimalist .carouselCard {
    max-height: unset;
    height: 50vh;
    border-radius: 20px;
  }

  .gallery.minimalist .carouselCard img {
    border-radius: 20px;
  }

  .gallery.minimalist .carouselWrapper {
    max-height: unset;
    height: 50vh;
  }

  .gallery.minimalist .carouselPagination {
    top: 102.5%;
  }

  .gallery.minimalist .arrow {
    top: 50%;
  }

  .gallery.minimalist .leftArrow {
    margin-left: 15px;
    transform: unset;
    font-size: 20px;
    width: 40px;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.5);
    color: #fff;
  }

  .gallery.minimalist .rightArrow {
    margin-right: 15px;
    transform: unset;
    font-size: 20px;
    width: 40px;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.5);
    color: #fff;
  }
}
