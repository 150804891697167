.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  z-index: 100;
  color: rgba(255, 255, 255);
  width: 100%;
  font-family: "Poppins", serif;
  font-family: inherit;
}

.header img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 14px;
  margin-left: 5px;
  border-radius: 10px;
}

.logoTitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  /* font-size: 27px; */
  font-size: clamp(18px, 1.4vw, 23px);
  color: rgba(255, 255, 255);
}

.menu ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
}

.menuIcon {
  display: none;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 2vw;
}

.menu li {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  font-size: clamp(8px, 1.2vw, 18px);
  text-align: center;
}

.menu li.active {
  color: var(--primary-color);
  font-weight: 700;
}

.enquiry,
.signIn {
  color: #fff;
  background-color: #496ce6;
  background-color: var(--primary-color);
  border: none;
  padding: 10px 30px;
  border-radius: 45px;
  font-family: inherit;
  font-weight: 700;
  height: fit-content;
  font-size: clamp(14px, 1.1vw, 18px);
  box-shadow: 1px 3px 2px #0000006f;
  cursor: pointer;
}

.call {
  font-style: italic;
  /* font-size: 22px; */
  /* font-size: 0.9vw; */
  font-weight: 300;
  border-left: 1px solid #dedede;
  padding: 0 0.7vw;
  color: #fff !important;
  font-size: clamp(10px, 1vw, 15px);
}

.call span {
  display: flex;
  align-items: center;
  gap: 0.6vw;
  font-weight: 600;
  /* font-size: 1.1vw; */
  font-size: clamp(10px, 1vw, 17px);
}

.menu li:hover {
  color: #ffffff;
}

.enquiry:hover,
.signIn:hover {
  opacity: 0.5;
  box-shadow: 1px 4px 2px #0000006f;
  color: #ffffffcc;
}

.header.batchesPage {
  background-color: rgba(0, 0, 0, 0.5);
}

.header.batchesPage .enquiry,
.header.batchesPage .signIn {
  box-shadow: unset;
}

.simpleui .menu li.active {
  color: #fff;
}

@media screen and (max-width: 750px) {
  .menu {
    display: none;
  }

  .actionButtons {
    display: none;
  }

  .menuIcon {
    display: block;
    font-size: 40px;
    cursor: pointer;
  }

  .header img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .logoTitle {
    font-size: 24px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    color: #fff;
  }

  .header.batchesPage {
    background-color: #fff;
    color: #212121;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .header.batchesPage .logoTitle {
    color: #212121;
  }
}
