.batches {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2vw 10%;
  width: 100%;
}

.batches .titleHeader {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  align-items: center;
}

.batches h2 {
  /* font-size: 1.8vw; */
  font-size: var(--section-title-font-size);
  font-weight: bold;
  margin-bottom: 4vh;
}

.batches .showAll {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  color: #496ce6;
  color: var(--primary-color);
  font-weight: 600;
}

.batches .noShowAll {
  display: none;
}

.batches .batchList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.8vw;
}

.batchCard {
  width: 80vw;
  border-radius: 20px;
  border: 0;
  box-shadow: 0 0 9px #acabab;
  background-color: white;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 48vw; */
  /* height: fit-content; */
}

.batchDetails {
  display: flex;
  align-items: flex-start;
  gap: 1.8vw;
  border-bottom: 1px solid #bdbdbd;
  height: fit-content;
  height: 310px;
  max-height: 45vh;
  /* max-height: fit-content; */
}

.batchContent {
  display: flex;
  flex-direction: column;
  min-width: 60vw;
  max-width: 60vw;
}

.batchFullContent {
  display: flex;
  flex-direction: column;
  min-width: 60vw;
  max-width: 60vw;
  transform: translateX(-27vw);
}

.image {
  min-width: 25vw;
  max-width: 25vw;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  position: relative;
}

.batchFull {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 25vw;
  max-width: 25vw;
  height: 100%;
  font-size: 100px;
  left: -26.8vw;
  top: 0;
  color: #fff;
  background-color: rgb(0, 0, 0, 0.5);
  border-top-left-radius: 20px;
  z-index: 2;
}

.batchFull .text {
  display: flex;
  font-size: 20px;
  margin-top: 1vw;
}

.title {
  padding-top: 10px;
  /* font-size: 1.5vw; */
  font-size: 24px;
  color: #212121;
  font-weight: 700;
  width: 85%;
  line-height: 2;
  padding-bottom: 0;
  line-height: 2;
  border-bottom: 3px solid #496ce6;
  border-color: var(--primary-color);
  margin-bottom: 1vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.desc {
  /* font-size: 1.1vw; */
  font-size: 16px;
  color: #212121;
  width: 84%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}

.aboutBatch {
  background-color: #f5f5f5;
  width: 80%;
  border-radius: 10px;
  padding: 5px 20px 15px;
  color: #212121;
  /* font-size: 1.1vw; */
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 10px;
  /* height: 14vh; */
}

.bookingDetails,
.bookingFullDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.pricing {
  display: flex;
  align-items: center;
}

.availableSeats {
  color: #ef5350;
  font-size: 20px;
  font-weight: 700;
}

.batchPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1vw;
  gap: 5px;
}

.originalPrice {
  color: #bdbdbd;
  text-decoration: line-through;
  font-size: 20px;
  font-weight: 700;
}

.discountPrice {
  /* font-size: 1.3vw; */
  font-size: 26px;
  font-weight: 700;
}

.batchCard .bookNowBtn {
  border-radius: 50px;
  border: none;
  font-weight: normal;
  color: white;
  background-color: #496ce6;
  background-color: var(--primary-color);
  /* padding: 12px 30px; */
  font-size: 14.18px;
  font-weight: 600;
  width: 170px;
  height: 50px;
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 1vw;
  margin-right: 1.5vw;
}

.batchCard .bookNowBtn:hover {
  opacity: 0.5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.aboutBatchMobile {
  display: none;
}

.location {
  text-transform: capitalize;
}

.icon {
  color: var(--primary-color);
}

/* Batches Page */

.batches.batchesPage .titleHeader {
  font-size: 32px;
  margin: 2vw 0 3vw 0;
  line-height: 0;
  font-weight: 700;
}

.batchCard.batchesPage {
  padding: 0;
  height: fit-content;
  box-shadow: 0 0 4px #acabab;
  border-radius: 20px;
}

/* Member page */

.batchCard.membersPage {
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.batchCard.membersPage .image {
  min-width: 35%;
  max-width: 35%;
}
.batchCard.membersPage .batchContent {
  min-width: 60%;
  max-width: 60%;
  padding-bottom: 70px;
}
.batchCard.membersPage .batchFullContent {
  min-width: 60%;
  max-width: 60%;
  transform: unset;
}
.batchCard.membersPage .batchFull {
  display: none;
}
.batchCard.membersPage .bookingDetails,
.batchCard.membersPage .bookingFullDetails {
  display: none;
}
.batchCard.membersPage .batchDetails {
  border-bottom: unset;
  height: clamp(325px, 47vh, 360px);
  max-height: unset;
}
.batchCard.membersPage .batchPrice {
  display: none;
}
.batchCard.membersPage .title {
  font-size: 20.06px;
  /* padding-bottom: 5px; */
  padding-top: 15px;
  border-bottom-color: #496ce699;
  border-bottom-width: 2px;
  margin-bottom: 5px;
}
.batchCard.membersPage .desc {
  font-size: 15.55px;
}
.batchCard.membersPage .aboutBatch {
  font-size: 14px;
}
.batchCard.membersPage .pricing {
  display: none;
}
.batchCard.membersPage .image {
  border-radius: 15px 0 0 15px;
}
.batchCard.membersPage .joinNowBtn {
  width: fit-content;
  padding: 10px 40px;
  align-self: center;
  background-color: #496ce6;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-family: inherit;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700;
  transform: translateX(-15%);
  position: absolute;
  bottom: 0;
  cursor: pointer;
  margin-top: 30px;
}

@media (max-width: 750px) {
  .batches {
    width: 100%;
  }

  .batches .titleHeader h2 {
    /* font-size: 3vh; */
    margin: 0;
  }

  .batches .titleHeader {
    margin-bottom: 3vh;
    margin-top: 1vh;
    align-items: center;
  }

  .batches .batchList {
    gap: 2.5vh;
  }

  .batches .showAll {
    right: -2vw;
    font-size: 14px;
    top: 20px;
  }

  .batchCard {
    width: 90vw;
    border-radius: 10px;
  }

  .aboutBatch {
    display: none;
  }

  .batchCard {
    border-radius: 20px;
  }

  .batchDetails {
    display: flex;
    flex-direction: column;
    max-height: unset;
    min-height: unset;
    height: unset;
    border-bottom: none;
    position: relative;
  }

  .image {
    object-fit: cover;
    min-width: unset;
    max-width: unset;
    width: 100%;
    height: 30vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .batchFull {
    min-width: 90vw;
    max-width: 90vw;
    border-top-right-radius: 20px;
    height: 30vh;
    left: unset;
    position: absolute;
    transform: translateY(0%);
  }

  .batchFull .text {
    font-size: 16px;
    margin-top: 15px;
  }

  .batchFullContent {
    transform: translateX(0);
  }

  .batchContent,
  .batchFullContent {
    margin-left: 3.5vw;
    min-width: 85vw;
    max-width: 85vw;
  }

  .batchCard .batchContent .title,
  .batchCard .batchFullContent .title {
    /* font-size: 2.1vh; */
    font-size: 16px;
    padding-top: 0;
    width: 100%;
    margin: 0 2.5vw 0.5vh 0;
    border-bottom: 1px solid #496ce6;
    border-color: var(--primary-color);
  }

  .batchContent .desc,
  .batchFullContent .desc {
    order: 1;
    font-size: 14px;
    width: 95%;
    margin-top: 12px;
    color: #212121;
  }

  .aboutBatchMobile {
    order: 0;
    display: block;
    border-bottom: 1px solid var(--primary-color);
    /* width: 97.5%; */
    width: 100%;
    margin-right: 2.5vw;
  }

  .sessionFrequency {
    background-color: #f5f5f5;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    padding: 7px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    margin: 10px 0;
  }

  .days {
    display: flex;
    margin: 15px 0 10px;
  }

  .day {
    border-right: 3px solid #bdbdbd;
    padding: 0 10px;
    font-weight: 700;
    font-size: 13px;
    text-transform: capitalize;
  }

  .day:last-of-type {
    border-right: none;
  }

  .day:first-of-type {
    padding-left: 0;
  }

  .classDetails {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  .time,
  .duration,
  .mode {
    background-color: #f5f5f5;
    padding: 1.5vw;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 1vw;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
  }

  .bookingDetails,
  .bookingFullDetails {
    display: unset;
    padding: unset;
  }

  .availableSeats {
    padding-left: 3.5vw;
    font-size: 14px;
    margin-top: 13px;
    padding-bottom: 15px;
    font-weight: 700;
    border-bottom: 1px solid #bdbdbd;
  }

  .pricing {
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 6px;
  }

  .originalPrice {
    font-size: 13px;
  }

  .discountPrice {
    font-size: 20px;
  }

  .batchCard .bookNowBtn {
    font-size: 13px;
    font-weight: 700;
    align-self: flex-start;
    margin: 0.5vh 0 0 0;
    /* padding: 13px 40px; */
    width: 50vw;
    height: 40px;
    max-width: 160px;
  }

  /* BATCHES PAGE */

  .batches.batchesPage {
    padding: 10px 0;
  }

  .batches.batchesPage .batchList {
    padding-inline-start: 0;
  }

  .batches.batchesPage .titleHeader {
    margin: 4vh 0 4vh;
    font-size: 22px;
  }

  /* MEMBER PAGE */

  .batchCard.membersPage .image {
    min-width: 100%;
    max-width: 100%;
  }
  .batchCard.membersPage .image {
    border-radius: 15px 15px 0 0;
  }
  .batchCard.membersPage .batchDetails {
    height: unset;
  }
  .batchCard.membersPage .batchContent {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    margin-left: 5vw;
    padding: 0;
  }
  .batchCard.membersPage .batchFullContent {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
  .batchCard.membersPage .aboutBatch {
    display: block;
    /* width: 90vw; */
    width: 100%;
    background-color: unset;
    margin-bottom: 50px;
    padding: 5px 0 15px 0;
  }

  .batchCard.membersPage .joinNowBtn {
    width: 40vw;
    padding: 0;
    /* max-width: 160px; */
    height: 38.41px;
  }

  .batchCard.membersPage .title {
    width: 85%;
  }

  .batchCard.membersPage .time,
  .batchCard.membersPage .duration {
    background-color: unset;
    padding: unset;
    font-weight: unset;
  }

  .batchCard.membersPage .desc {
    display: none;
  }

  .batchCard.membersPage .days {
    margin: 0;
  }

  .batchCard.membersPage .aboutBatchMobile {
    display: none;
  }
}
