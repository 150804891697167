.batchesModal {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: unset;
  color: #212121;
  z-index: 1200;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: black;
  transition: background-color 5000s ease-in-out 0s;
}

.batchForm {
  width: 50vw;
  gap: 1vw;
  margin: 0 auto;
}

.batchInput {
  width: 100%;
  margin: 15px 0;
  width: 100%;
  font-family: inherit;
}

/* .batchForm input {
  min-width: 100%;
  max-width: 100%;
} */

.batchForm textarea {
  height: 140px;
  resize: none;
}

.batchForm .input:disabled {
  background-color: #fff;
}

.submit {
  border: none;
  padding: 15px 46px;
  background-color: #496ce6;
  background-color: var(--primary-color);
  border-radius: 50px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: inherit;
  font-weight: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 1.6vh;
  margin-bottom: 5vh;
}

.submit:hover {
  opacity: 0.5;
}

.button {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.checkMark {
  display: none;
}

.payments {
  color: #212121;
  background-color: #fff;
  box-shadow: 1px 1px 1px #f5f5f5;
  border-radius: 10px;
  font-size: 21px;
  border: none;
  max-height: 90vh;
  border: 1px solid #424242;
  padding: 0.7vw 0.5vw 2vw 2vw;
  margin: 0 auto;
}

li {
  list-style: none;
}

.previewImage {
  width: 115px;
  margin-top: 0;
  position: relative;
  top: -30px;
}

.previewImage .close {
  position: relative;
  left: 75%;
  border-radius: 50%;
  color: #fff;
  top: 30px;
  background-color: #75757599;
  padding: 3px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.previewImage .image {
  height: 115px;
  width: 115px;
  display: block;
  border-radius: 15px;
}

.refNumber {
  color: #212121;
  border: none;
  padding: 15px 0 7.5px 0;
  display: block;
  width: 95%;
  font-family: inherit;
  font-size: 18px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 22px;
}

.upload {
  font-size: 12.87px;
}

.uploadFiles {
  display: none;
}

.upload .text {
  margin-top: 14px;
  font-size: 12px;
  transition: 0.2s all;
}

.upload label {
  border: 2px solid var(--primary-color);
  /* font-size: 0.8vw; */
  font-size: 12.87px;
  font-weight: 700;
  color: var(--primary-color);
  padding: 6px 20px;
  border-radius: 50px;
  cursor: pointer;
}

.upload label:hover {
  opacity: 0.5;
}

.refNumber:focus {
  outline: none;
}

.closeButton {
  display: none;
}

.errorText {
  color: #ef5350;
  font-weight: 800;
  font-size: 15px;
  margin-top: 1vh;
  margin-left: 0.2vw;
}

/* SIMPLEUI */

.simpleui .upload label {
  border-color: #496ce6;
  color: #496ce6;
}

.simpleui .submit {
  background-color: #496ce6;
}

.simpleui .submit .checkMark {
  color: #496ce6;
}

/* MIDNIGHTZEN */

.midnightzen {
  background-color: #000;
  color: #fff;
}

.midnightzenHeader {
  box-shadow: unset;
}

.midnightzen input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #000 inset;
  -webkit-text-fill-color: #fff;
}

.midnightzen .arrow .backBtn {
  background-color: #424242;
  color: #fff;
  box-shadow: unset;
}

.midnightzen .payments {
  border: 1px solid #fff;
}

.midnightzen .paymentDetailsCard,
.midnightzen .paymentModal {
  background-color: #000;
  color: #fff;
}

.midnightzen .modalHeader .title {
  color: #fff;
}

.midnightzen .scan {
  background-color: #212121;
  padding: 1vw;
}

.midnightzen .bankDetails {
  background-color: #212121;
  color: #fff;
  padding: 1vw;
}

.midnightzen .details li {
  color: #fff;
}

.midnightzen .or {
  background-color: #212121;
  color: #747474;
}

.midnightzen .or::after,
.midnightzen .or::before {
  background-color: #cecece;
}

.midnightzen input {
  background-color: #000;
  color: #9e9e9e;
}

.midnightzen .payments {
  background-color: #000;
}

/* .midnightzen .submit {
  background-color: #496ce6;
}

.midnightzen .submit .checkMark {
  color: #496ce6;
} */

/* ELEVATE */

/* .elevate button {
  border-radius: 10px;
  background-color: #496ce6;
}

.elevate .upload label {
  border-color: #496ce6;
  color: #496ce6;
} */

/* EDGEFUSION */

.edgefusion button {
  padding: 0;
  border-radius: unset;
  width: 158px;
  height: 50px;
  font-size: 16px;
  font-style: italic;
  text-transform: capitalize;
  /* background-color: #496ce6; */
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
}

/* .edgefusion .upload label,
.midnightzen .upload label {
  border-color: #496ce6;
  color: #496ce6;
} */

/* MINIMALIST */

.minimalist .submit {
  background-color: #946199;
  background-color: var(--primary-color);
  font-weight: 600;
  border-radius: unset;
  text-transform: capitalize;
  font-family: "Manrope", serif;
}

.minimalist .submit .checkMark {
  color: #946199;
  color: var(--primary-color);
}

.minimalist .upload label {
  border-color: #946199;
  border-color: var(--primary-color);
  color: #946199;
  color: var(--primary-color);
}

@media screen and (max-width: 750px) {
  .batchForm {
    width: unset;
  }

  .batchForm textarea {
    height: 7vh;
  }

  .batchInput {
    width: 90vw;
    margin: 15px auto;
  }

  .batchInput input,
  .batchForm select {
    font-size: 16px;
  }

  .checkMark {
    display: inline;
    background-color: #fff;
    border-radius: 50%;
    color: var(--primary-color);
    font-size: 20px;
    text-align: center;
    display: flex;
    padding: 2px;
    align-items: center;
    justify-content: center;
    margin-right: 1.3vh;
  }

  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18.3px;
    font-weight: 500;
    text-transform: capitalize;
    width: 90%;
    padding: 3vh 0;
    margin-bottom: 10px;
  }

  .button {
    box-shadow: 4px 1px 8px 4px #bdbdbd;
    margin-top: 4vh;
  }

  .payments {
    border-radius: unset;
    border: unset;
    padding: 0;
    min-height: fit-content;
    width: 90vw;
    box-shadow: unset;
  }

  .closeButton {
    font-size: 20px;
    border-radius: 50%;
    box-shadow: 0 0px 7px 1px #bdbdbd;
    top: 1.5vh;
    position: absolute;
    padding: 0.5vh;
    right: 2vh;
    justify-content: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refNumber {
    font-size: 16px;
    margin-top: 1vh;
    font-weight: 500;
    padding-bottom: 1vh;
    width: 100%;
  }

  .upload input {
    display: none;
  }

  .upload label {
    padding: 6.4px 18px;
    font-size: 17.1px;
    border-radius: 30px;
  }

  .upload p {
    font-size: 17.1px;
  }

  .previewImage {
    width: 164px;
  }

  .previewImage .image {
    width: 164px;
    height: 164px;
    margin-bottom: unset;
  }

  .previewImage .close {
    font-size: 25px;
    padding: 3px;
    left: 80%;
  }

  .midnightzen .payments {
    border: none;
  }

  .elevate .submit {
    box-shadow: unset;
    padding: 0 0;
    height: 50px;
    font-size: 18px;
  }

  .elevate .text {
    margin-bottom: 1vh;
  }

  .elevate .checkMark {
    font-size: 18px;
    color: #496ce6;
  }

  .elevate .button {
    margin-bottom: 50px;
    box-shadow: unset;
  }

  .edgefusion .checkMark {
    display: none;
  }

  .edgefusion .button {
    box-shadow: unset;
    margin-bottom: 30px;
  }

  /* EDGEFUSION */

  .edgefusion .submit {
    width: unset;
    border-radius: unset;
    width: 163.96px;
    clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
    padding: unset;
    height: 41.41px;
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
  }
}
