.usp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2vw 10%;
  width: 100%;
}

.usp .titleHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.usp h2 {
  /* font-size: 2vw; */
  font-size: var(--section-title-font-size);
  font-weight: bold;
  margin-bottom: 4vh;
}

.usp .show-all {
  position: absolute;
  right: 0;
}

.usp .no-show-all {
  display: none;
}

.usp ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3vw;
  width: 80vw;
  max-width: 80vw;
}

.uspCard {
  border-radius: 15px;
  border: 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  /* padding: 1.5vh 1.5vw 1.6vh 1.5vw; */
  padding: 25px 1.75vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* min-height: 14vw; */
  height: 100%;
  /* width: 25vw; */
  position: relative;
  cursor: pointer;
  max-width: 25.67vw;
}

.uspCard:hover {
  background-color: #f2eeee33;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.uspCard img {
  object-fit: cover;
  width: 90px;
  height: 90px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.uspCard .title {
  /* font-size: 1.3vw; */
  font-size: var(--card-title-font-size);
  font-weight: 700;
  color: #212121;
  text-align: start;
  margin-bottom: 10px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.uspCard .desc {
  /* font-size: 1vw; */
  font-size: var(--card-desc-font-size);
  color: #424242;
  text-align: start;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 32px;
}

.readMore {
  position: absolute;
  color: inherit;
  /* font-size: 1vw; */
  font-size: var(--card-desc-font-size);
  color: #496ce6;
  color: var(--primary-color);
  top: 85%;
  font-weight: bold;
}

/* MIDNIGHTZEN */

.usp.midnightzen {
  background-color: #000;
  font-family: "Nunito Sans", serif;
  font-family: inherit;
  padding: 25px 10% 50px;
  border-bottom: 1px solid #757575;
}

.usp.midnightzen .titleHeader h2 {
  color: #fff;
}

.uspCard.midnightzen {
  background-color: #212121;
  align-items: center;
}

.uspCard.midnightzen .title {
  color: #fff;
  text-align: center;
}

.uspCard.midnightzen .desc {
  color: #bbb;
  margin-bottom: 44px;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  text-align: center;
}

.usp.midnightzen .uspCard .readMore {
  color: #fff;
}

/* ELEVATE */

.usp.elevate {
  font-family: "Nunito", sans-serif;
  font-family: inherit;
  width: 80vw;
  padding: 0;
  margin: 2vw auto 0;
  padding-bottom: 2.5vw;
  border-bottom: 1px solid #bdbdbd;
}

.usp.elevate h2 {
  margin-bottom: unset;
}

.usp.elevate .titleHeader {
  justify-content: flex-start;
  text-align: start;
  margin: 1vw 0 3vw;
  padding: 0;
}

.usp.elevate .desc {
  color: #5d5d5d;
}

.usp.elevate .uspCard {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 13px, rgba(0, 0, 0, 0.1) 0px 3px 10px;
  font-family: "Nunito Sans", serif;
  font-family: inherit;
}

.usp.elevate .uspCard:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 27px,
    rgba(0, 0, 0, 0.23) 0px 6px 10px;
}

.usp.elevate ul {
  gap: 3.3vw;
}

.usp.elevate .readMore {
  font-family: "Nunito", sans-serif;
  font-family: inherit;
  /* color: #496ce6; */
}

/* MINIMALIST */

.usp.minimalist {
  font-family: "Manrope", serif;
  font-family: inherit;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 5vh;
  margin-bottom: 3.5vh;
}

.usp.minimalist .titleHeader {
  justify-content: flex-start;
  text-align: start;
  padding: 0;
}

.usp.minimalist .uspCard {
  border-radius: unset;
  box-shadow: unset;
  padding-left: 0;
}

.usp.minimalist .readMore svg {
  display: none;
}

.usp.minimalist .readMore {
  color: #946199;
  color: var(--primary-color);
  text-decoration: underline;
}

@media screen and (max-width: 750px) {
  .usp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3vh 4vw 3vh 4vw;
    margin: 0;
  }

  .usp h2 {
    margin: 1vh 0 3vh 0;
    /* font-size: 3vh; */
    font-weight: bold;
  }

  .usp ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .uspCard {
    border-radius: 20px;
    border: 0;
    box-shadow: 0 0 9px #acabab;
    background-color: white;
    padding: 1vh 2vh 0.5vh 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    width: 90vw;
    max-width: unset;
  }

  .uspCard img {
    object-fit: cover;
    height: 6vh;
    width: 6vh;
    margin: 1vh 0;
  }

  .uspCard .title {
    /* font-size: 2.5vh; */
    font-weight: bold;
    color: #212121;
    text-align: start;
    margin-bottom: 1vh;
  }

  .uspCard .desc {
    /* font-size: 2.15vh; */
    color: #757575;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
  }

  .uspCard .readMore {
    position: unset;
    font-weight: bold;
    margin-bottom: 1.5vh;
    font-size: var(--secondary-font-size);
  }

  /* MIDNIGHTZEN */

  .uspCard.midnightzen {
    box-shadow: unset;
  }

  .usp.midnightzen .uspCard .readMore {
    color: #fff;
    font-size: 15px;
  }

  .uspCard.midnightzen .desc {
    margin-bottom: 15px;
  }

  /* ELEVATE */

  .usp.elevate {
    border-bottom: none;
  }

  .usp.elevate .titleHeader {
    justify-content: center;
    text-align: center;
    margin: 3vh 0;
  }

  .usp.elevate ul {
    gap: 2vh;
  }

  .usp.elevate .uspCard {
    border-radius: 15px;
  }

  /* MINIMALIST */

  .usp.minimalist {
    font-family: "Inter", serif;
  }

  .usp.minimalist .titleHeader {
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .usp.minimalist .uspCard {
    padding: 0 10px;
  }
}
