.batchesModal {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: unset;
  color: #212121;
  z-index: 1200;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: black;
  transition: background-color 5000s ease-in-out 0s;
}

.batchesModalHeader {
  display: flex;
  align-items: center;
  gap: 2vw;
  padding-left: 3.5vw;
  padding-top: 16.15px;
  padding-bottom: 16.15px;
  box-shadow: 3px 2px 5px #bdbdbd;
  margin-bottom: 30px;
}

.arrow .backBtn {
  background-color: #fff;
  color: #212121;
  font-size: 25px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.backBtn:hover {
  background-color: #e6e3e366;
  color: black;
}

.batchImage img {
  width: 2.7vw;
  height: 2.7vw;
  border-radius: 50%;
}

.batchDetails {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
}

.batchTitle {
  font-size: 22px;
  font-weight: 700;
}

/* .batchPrice {
  font-size: 1vw;
} */

.title {
  color: #bdbdbd;
}

.batchForm .input:disabled {
  background-color: #fff;
}

.paymentModal {
  width: 100%;
  border-color: #757575;
}

.button {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2vw;
}

.checkMark {
  display: none;
}

/* SIMPLEUI */

/* MIDNIGHTZEN */

.midnightzen {
  background-color: #000;
}

.midnightzen .batchTitle {
  color: #fff;
}

.midnightzen .backBtn {
  background-color: #000;
  color: #fff;
}

.midnightzen .batchesModalHeader {
  box-shadow: unset;
}

/*payments*/

@media screen and (max-width: 750px) {
  .batchesModalHeader {
    box-shadow: unset;
    padding-left: 2.5vw;
    margin-top: 1vh;
    margin-bottom: 0;
    padding-bottom: 1.3vh;
  }

  .batchImage {
    display: none;
  }

  .arrow .backBtn {
    box-shadow: unset;
    font-size: 25px;
  }

  .backBtn:hover {
    background-color: unset;
    color: black;
    opacity: 0.8;
  }

  .batchPrice {
    display: none;
  }

  .batchTitle {
    font-size: 22px;
  }

  .simpleui .batchTitle {
    font-weight: 600;
  }
}
