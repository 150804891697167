.paymentModal {
  padding: 1vw 1vw 2vw 2.5vw;
  color: #212121;
  background-color: #fff;
  box-shadow: 1px 1px 1px #f5f5f5;
  border-radius: 10px;
  font-size: 1.1vw;
  width: 50vw;
  border: none;
  max-height: 90vh;
  margin: auto auto;
}

.title {
  font-size: 1.1vw;
}

.closeButton {
  display: flex;
  justify-content: flex-end;
  font-size: 2vw;
  padding: 5px;
  cursor: pointer;
  position: relative;
  top: -1.5vh;
}

.closeButton:hover {
  background-color: #f5f5f5cc;
  border-radius: 5px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: 1vh 0 0;
}

li {
  list-style: none;
}

.paymentDetailsCard {
  background-color: #f5f5f5;
  display: flex;
  /* margin-top: 1vw; */
  margin-right: 1.5vw;
  padding: 1vw 1vw;
}

.scan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  /* border-right: 1px solid #212121; */
}

.scan img {
  margin-top: 1vw;
  height: 10vw;
  margin-right: 1vw;
  width: 10vw;
  object-fit: cover;
}

.bankDetails {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.details {
  margin-top: 20px;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
}

.bankDetails li {
  margin-bottom: 0.5vw;
}

.values {
  padding-inline-start: 0;
  word-break: break-word;
}

.details {
  display: flex;
  gap: 1vw;
}

.values {
  font-weight: 700;
}

.previewImage {
  width: 7.5vw;
  margin-top: 0;
  position: relative;
  top: -2vw;
}

.previewImage .close {
  position: relative;
  left: 75%;
  top: 1.75vw;
  border-radius: 50%;
  color: #fff;
  background-color: #75757599;
  padding: 0.2vw;
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.previewImage .image {
  height: 7.5vw;
  width: 7.5vw;
  display: block;
  border-radius: 14px;
}

.or {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #747474;
  font-size: 1vw;
}

.or::before {
  content: "";
  background-color: #bdbdbd;
  height: 50%;
  width: 1px;
  position: relative;
  left: 0.7vw;
}

.or::after {
  content: "";
  background-color: #bdbdbd;
  height: 50%;
  width: 1px;
  position: relative;
  left: 0.7vw;
}

.refNumber {
  border: none;
  padding: 1vw 0 0.5vw 0vw;
  display: block;
  width: 90%;
  font-size: 1vw;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 1.5vw;
  /* margin-top: 0.5vh; */
}

.submitBtn {
  background-color: transparent;
  border: 1px solid #496ce6;
  color: #496ce6;
  padding: 0.5vw 1vw;
  border-radius: 25px;
}

.upload {
  font-size: 0.9vw;
}

.uploadFiles {
  display: none;
}

.upload label {
  border: 2px solid #496ce6;
  font-size: 0.8vw;
  font-weight: 750;
  color: #496ce6;
  padding: 0.4vw 1.3vw;
  border-radius: 50px;
  cursor: pointer;
}

.upload label:hover {
  background-color: #c4c1c133;
}

.upload .text {
  transition: 0.2s all;
  margin-top: 0.8vw;
}

.errorText {
  color: #ef5350;
  font-weight: 800;
  font-size: 1vw;
  margin-top: 1vh;
  margin-left: 0.2vw;
}

.refNumber:focus {
  outline: none;
}

.submit button {
  color: #fff;
  background-color: #0c66e4;
  border: none;
  padding: 1vw 3vw;
  border-radius: 50px;
  display: flex;
  font-size: 1vw;
  font-family: inherit;
  margin: 1vw auto 0 auto;
  cursor: pointer;
}

.submit button:hover {
  background-color: #0c66e499;
}

.title {
  font-size: 1vw;
}

.newMemberFormPayments .bankDetails li {
  margin-bottom: 5px;
}

.newMemberFormPayments .title {
  font-size: 21px;
  margin-bottom: 10px;
}

.newMemberFormPayments .scan,
.newMemberFormPayments .bankDetails {
  font-size: 18px;
}

.newMemberFormPayments .details {
  margin-top: 18px;
}

.newMemberFormPayments .labels,
.newMemberFormPayments .values {
  font-size: 16px;
}

.newMemberFormPayments.midnightzen {
  color: #fff;
}

.newMemberFormPayments.midnightzen .scan,
.newMemberFormPayments.midnightzen .bankDetails {
  background-color: #212121;
}

.newMemberFormPayments.midnightzen .or {
  background-color: #212121;
}

.newMemberFormPayments.midnightzen .paymentDetailsCard {
  background-color: #000;
  color: #fff;
}

.newMemberFormPayments.midnightzen .scan {
  background-color: #212121;
  padding: 1vw;
}

.newMemberFormPayments.midnightzen .bankDetails {
  background-color: #212121;
  color: #fff;
  padding: 1vw;
}

.newMemberFormPayments.midnightzen .details li {
  color: #fff;
}

.newMemberFormPayments.midnightzen .or {
  background-color: #212121;
  color: #747474;
}

.newMemberFormPayments.midnightzen .or::after,
.newMemberFormPayments.midnightzen .or::before {
  background-color: #cecece;
}

@media screen and (max-width: 750px) {
  .paymentModal {
    min-width: 100vw;
    color: #212121;
    font-size: 2.2vh;
    font-weight: 500;
    min-height: 100vh;
    padding: 0;
    border-radius: unset;
  }

  .paymentDetailsCard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: unset;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .closeButton {
    font-size: 20px;
    border-radius: 50%;
    top: 1.5vh;
    position: absolute;
    padding: 0.5vh;
    right: 2vh;
    justify-content: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .modalHeader {
    display: unset;
  }

  .title {
    font-size: 2.3vh;
    margin: 6vh 0 2vh 7.5vw;
  }

  .scan {
    background-color: #f5f5f5;
    display: flex;
    margin-bottom: 3vh;
    width: 85%;
    padding: 1vh;
  }

  .scan img {
    width: 20vh;
    height: 20vh;
    margin: 1.5vh 0;
  }

  .bankDetails {
    background-color: #f5f5f5;
    margin-top: 0vh;
    width: 85%;
    padding: 2.5vh 1vh;
  }

  .newMemberFormPayments .bankDetails,
  .newMemberFormPayments .scan {
    width: 100%;
  }

  .newMemberFormPayments .title {
    margin: 1vh 0;
    font-size: 20px;
    font-weight: 400;
  }

  .line {
    width: 85%;
    height: 1px;
    background-color: #bdbdbd;
    margin: 2vh 0;
    position: relative;
  }

  .or {
    font-size: 2vh;
    position: relative;
    top: -3.5vh;
    background-color: #fff;
    padding: 0 1vh;
  }

  .bankDetails li {
    color: #212121;
    font-size: 1.8vh;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .details {
    margin-top: 2vh;
    gap: 1vh;
  }

  .values {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .values li {
    font-weight: 700;
  }

  .refNumber {
    font-size: 1.5vh;
    margin-left: 7vw;
    margin-top: 1.5vh;
    font-weight: 500;
    padding-bottom: 1vh;
    margin-bottom: 2vh;
    color: #bdbdbd;
  }

  .upload input {
    display: none;
  }

  .upload label {
    margin-left: 7vw;
    padding: 0.7vh 2vh;
    font-size: 1.5vh;
    border-radius: 30px;
  }

  .upload p {
    font-size: 1.5vh;
    margin-left: 7vw;
    padding-top: 2vh;
  }

  .errorText {
    font-size: 1.5vh;
  }

  .submit button {
    font-size: 1.5vh;
    padding: 1vh 3vh;
    border-radius: 30px;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .previewImage .image {
    width: 18vh;
    height: 18vh;
    margin-top: 0;
    margin-left: 7vw;
    margin-bottom: 1vh;
    display: block;
    position: relative;
  }

  .previewImage .close {
    font-size: 20px;
    padding: 1px;
    top: 25px;
    left: 18vh;
    z-index: 2;
  }
}
