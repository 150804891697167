/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap"); */

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #212121;
  min-height: 210px;
  padding: 2vw 8vw 30px;
}

.about-us {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  flex: auto;
  margin-left: 9vw;
  position: relative;
  font-family: inherit;
  padding-bottom: 20px;
}

.contact {
  display: flex;
  flex: 1;
  width: 100%;
  height: unset;
  padding-bottom: 40px;
  flex-direction: column;
}

.app-logo-title {
  align-items: center;
  display: flex;
}

.app-logo-title img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 12px;
  border-radius: 10px;
}

.app-logo-title span {
  margin: 0;
  /* font-size: 1.5vw; */
  font-size: 26px;
  font-weight: bold;
  color: white;
}

.address-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  color: #ffffff;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.address-title {
  /* font-size: 1.2vw; */
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1vw;
}

.address {
  /* font-size: 1.1vw; */
  font-size: var(--secondary-font-size);
  font-weight: 300;
  line-height: 1.9;
  margin: 0;
  padding-right: 30px;
  white-space: pre-line;
}

.contact-sales {
  color: #fff;
  margin-top: 30px;
}

.contact-sales-mobile {
  display: none;
}

.title {
  font-weight: 600;
  /* font-size: 1.2vw; */
  font-size: 18px;
  margin-bottom: 1vw;
}

.salesDetails {
  /* font-size: 1.3vw; */
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.8vw;
}

.details {
  /* font-size: 1vw; */
  font-size: 16px;
  font-weight: 300;
}

.emailDetails {
  color: #1976d2;
  cursor: pointer;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  align-self: stretch;
  width: 100%;
  height: 100%;
}

.social span {
  /* font-size: 1.2vw; */
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.social-icons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.4vw;
  margin-top: 1.3vw;
}

.social-icons img {
  width: 44px;
  height: 44px;
  object-fit: cover;
  cursor: pointer;
}

.poweredBy {
  color: #bdbdbd;
  font-size: 12px;
}

/* footer > p {
  font-size: 0.95vw;
  margin-right: 6vw;
  color: rgb(255, 255, 255, 0.7);
} */

/* SIMPLEUI */

.simpleui {
  font-family: "Poppins", serif;
  font-family: inherit;
}

/* MIDNIGHTZEN */

.midnightzen {
  font-family: "Nunito", sans-serif;
  font-family: inherit;
}

/* ELEVATE */

.elevate,
.edgefusion {
  font-family: "Nunito", sans-serif;
  font-family: inherit;
}

.elevate .app-logo-title span,
.edgefusion .app-logo-title span {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
}

.elevate .address-title,
.edgefusion .address-title {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
}

.elevate .social span,
.edgefusion .social span {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
}

.elevate .title,
.edgefusion .title {
  font-family: "Nunito Sans", serif;
  font-family: inherit;
}

/* MINIMALIST */

.about-us.minimalist {
  font-family: "Manrope", serif;
  font-family: inherit;
}

@media screen and (max-width: 750px) {
  footer {
    margin-top: 10vw;
    min-height: 50vh;
    padding: 4.5vh 0 2vh;
  }

  .about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 80%;
  }

  .app-logo-title {
    /* flex: unset; */
    justify-content: center;
    align-items: center;
    width: unset;
    height: unset;
    order: 0;
    padding-bottom: unset;
    margin-bottom: 0;
  }

  .app-logo-title img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .app-logo-title span {
    font-size: 24px;
  }

  .contact {
    padding-bottom: unset;
  }

  .contact .contact-sales {
    display: none;
    /* text-align: center; */
  }

  .contact-sales-mobile {
    display: block;
    order: 2;
  }

  .contact-sales-mobile .contact-sales {
    margin-top: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* .contact-sales-mobile .contact-sales-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .contact-sales-mobile .title {
    line-height: 2;
  }

  .title {
    font-size: 20px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .salesDetails {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .details {
    font-size: 14px;
  }

  .emailDetails {
    display: block;
    color: #1976d2;
    margin-top: 10px;
    font-size: 14px;
    order: 3;
  }

  .address-div {
    align-items: center;
    margin-left: unset;
    flex: unset;
    height: unset;
    width: unset;
    margin: 30px 0;
    text-align: center;
    order: 1;
    color: #ffffff;
  }

  .address-div .app-name {
    font-weight: 400;
    margin-bottom: 1.7vw;
  }

  .address {
    font-size: 14px;
    font-weight: 300;
    line-height: 2;
    padding-right: 0;
  }

  .address-div a {
    margin-top: 1.5vw;
    font-size: 14px;
  }

  .social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: unset;
    align-self: unset;
    margin-left: unset;
    width: unset;
    height: unset;
    order: 5;
  }

  .social span {
    font-size: 20px;
    margin: 20px 0 0;
    line-height: 2;
  }

  .social-icons {
    justify-content: center;
    gap: 15px;
    margin-bottom: 1vh;
  }

  .social-icons img {
    width: 29.67px;
    height: 29.67px;
    object-fit: cover;
  }

  .poweredBy {
    color: rgb(255, 255, 255, 0.7);
    display: flex;
    font-size: 10px;
    font-weight: 400;
    margin-top: 10%;
    order: 6;
    margin-bottom: 30px;
  }
}
