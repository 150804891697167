.serviceRequestModal {
  padding: 20px;
  border: 0;
  margin: auto auto;
  border-radius: unset;
  color: #000;
  width: 45vw;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 20px;
  min-width: 550px;
}

.serviceRequestModal::-webkit-scrollbar {
  display: none;
}

.serviceRequestModal::backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.closeButton {
  padding: 5px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #fff;
  color: #424242;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.closeButton:hover {
  background-color: #e6e3e366;
  color: black;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0.5vh 0 4vh;
  display: flex;
  justify-content: center;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.textarea:focus {
  outline: none;
}

/* .input::placeholder,
.textarea::placeholder {
  color: #757575;
  font-size: 20px;
} */

.formInput {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.formInput input {
  font-family: inherit !important;
}

.form label {
  font-family: inherit;
}

.textarea {
  border-radius: 4px;
  font-family: inherit;
  resize: none;
  font-size: 21px;
  font-weight: 400;
  height: 120px;
  padding: 10px 20px;
}

.button {
  display: flex;
  justify-content: center;
  /* margin-top: 50px; */
}

.submit {
  border-radius: 50px;
  border: none;
  font-family: inherit;
  align-self: center;
  font-weight: 500;
  color: white;
  background-color: var(--primary-color);
  padding: 11px 40px;
  font-size: 17px;
  cursor: pointer;
}

.submit:hover {
  opacity: 0.5;
}

.form .textarea::placeholder {
  font-size: 18px;
  font-weight: 400;
}

/* MIDNIGHTZEN */

.serviceRequestModal.midnightzen {
  background-color: #212121;
}

.serviceRequestModal.midnightzen .title {
  color: #fff;
}

.serviceRequestModal.midnightzen .closeButton {
  background-color: #424242;
  box-shadow: unset;
  color: #fff;
}

.serviceRequestModal.midnightzen textarea {
  background-color: #212121;
  box-shadow: unset;
  border-color: #fff;
  color: #fff;
}

.midnightzen input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #000 inset;
  -webkit-text-fill-color: #fff;
}

.midnightzen::backdrop {
  background-color: rgba(255, 255, 255, 0.27);
}

/* ELEVATE */

.serviceRequestModal.elevate .submit {
  border-radius: 10px;
}

/* EDGEFUSION */

.serviceRequestModal.edgefusion .title {
  font-style: italic;
}

.serviceRequestModal.edgefusion .submit {
  padding: 0;
  width: 195.56px;
  height: 44.69px;
  border-radius: unset;
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  font-size: 14px;
  font-style: italic;
}

/* MINIMALIST */

.serviceRequestModal.minimalist {
  font-family: "Manrope", serif;
  border-radius: unset;
  width: 45vw;
}

.serviceRequestModal.minimalist::backdrop {
  background-color: rgba(0, 0, 0, 0.45);
}

.serviceRequestModal.minimalist .submit {
  background-color: #946199;
  background-color: var(--primary-color);
  border-radius: unset;
}

@media screen and (max-width: 750px) {
  .serviceRequestModal {
    width: 90vw;
    border-radius: 10px;
    min-width: unset;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
  }

  .input {
    padding: 10px 10px;
    border-radius: 10px;
  }

  .submit {
    font-size: 16px;
    padding: 12px 50px;
  }

  .form textarea {
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 400;
  }

  .form .textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
  }

  .form label {
    font-size: 15px;
  }

  .closeButton {
    font-size: 20px;
    padding: 4px;
  }

  .submit {
    font-size: 18.3px;
    padding: 13px 60px;
  }

  .serviceRequestModal.edgefusion .title {
    font-style: normal;
  }

  .serviceRequestModal.elevate .submit {
    border-radius: 30px;
  }

  .serviceRequestModal.minimalist {
    width: 90vw;
  }
}
